<div class="modal-header main-modal-header" style="border-bottom: none" *ngIf="showHeader">
  <h4 class="modal-title mb-0 pb-0 ms-2 mb-0">
    {{ requestTitle }} [{{ locale('locale_key.pages.data_request.action.blank') }}]
  </h4>
  <button type="button" class="btn close" (click)="closeModal()">
    <span aria-hidden="true"><i class="las la-times"></i></span>
  </button>
</div>
<div class="modal-body" #scrollTop>
  <div *ngIf="loadingInfo">
    <div class="loader-wrapper mt-5">
      <div class="loader-container">
        <div class="ball-grid-pulse loader-primary">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!loadingInfo">
    <div class="row mb-0">
      <div class="col-12 mt-0 pt-0 mx-0 px-0">
        <div class="m-0 p-0">
          <div class="scroll pt-0 px-3">
            <!-- First page showing main questions -->
            <div class="accordion mt-2" id="sectionParentPreview" *ngIf="!selectedMainQuestion">
              <div class="accordion-item" *ngFor="let section of sections; let sectionIndex = index">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button p-0 pe-3 bg-white"
                    type="button"
                    data-bs-toggle="collapse"
                    attr.data-bs-target="#sectionAccordion{{ sectionIndex }}Preview"
                    aria-expanded="true"
                    attr.aria-controls="sectionAccordion{{ sectionIndex }}Preview"
                    id="section{{ sectionIndex }}Preview"
                  >
                    <div class="d-flex flex-row my-auto p-2">
                      <h5 class="my-auto ms-2 d-inline me-4" style="min-width: fit-content !important">
                        {{
                          locale('locale_key.modal.questionnaire_preview.section_number', {
                            sectionNumber: sectionIndex + 1
                          })
                        }}
                      </h5>
                      <ng-container *ngIf="editMapping" class="d-inline m-auto">
                        <span
                          class="badge badge-info w-auto py-auto my-auto me-2 px-2 text-wrap"
                          *ngIf="section.entity"
                          style="min-width: fit-content !important"
                        >
                          {{ section.entity.getTaxonomyTitle(taxonomyInfo) }}
                        </span>
                        <button
                          class="dropdown-item ms-0 ps-1"
                          type="button"
                          *ngIf="!section.taxonomyKey"
                          (click)="mappingEdited = true; section.editTaxonomy = true"
                        >
                          <i class="las la-edit ms-2" style="position: relative; top: 1px"></i>
                        </button>
                      </ng-container>
                    </div>
                  </button>
                </h2>
                <div
                  id="sectionAccordion{{ sectionIndex }}Preview"
                  class="accordion-collapse collapse show"
                  data-bs-parent="#sectionParent"
                >
                  <div class="accordion-body bg-comp-grey p-1">
                    <ng-container>
                      <div class="d-flex w-100 flex-column">
                        <div *ngIf="getMainQuestions(section) as questions">
                          <div *ngFor="let question of questions; let i = index">
                            <!-- Main question in the first page-->
                            <ng-container
                              *ngTemplateOutlet="questionTemplate; context: { question: question, level: 0 }"
                            ></ng-container>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <!-- Second page showing sub-tree of a selected main question  -->
            <div class="py-3 scroll-y" *ngIf="selectedMainQuestion">
              <div class="accordion" id="followUpAccordion">
                <ng-container>
                  <!-- Selected main question in the second page-->
                  <ng-container
                    *ngTemplateOutlet="questionTemplate; context: { question: selectedMainQuestion, level: 0 }"
                  ></ng-container>
                  <ng-container *ngIf="subtreeExpanded[selectedMainQuestion.id]">
                    <ng-container *ngTemplateOutlet="undoIconTemplate; context: { level: 0 }"></ng-container>
                  </ng-container>

                  <ng-container
                    *ngFor="
                      let childOfMainQuestion of selectedSection.getChildrenOfParent(selectedMainQuestion);
                      let i = index
                    "
                  >
                    <ng-container *ngIf="shown[childOfMainQuestion.id]">
                      <ng-container
                        *ngTemplateOutlet="questionTemplate; context: { question: childOfMainQuestion, level: 1 }"
                      ></ng-container>
                    </ng-container>
                    <ng-container *ngIf="shown[childOfMainQuestion.id] && subtreeExpanded[childOfMainQuestion.id]">
                      <ng-container
                        *ngTemplateOutlet="
                          followUpQuestionExpanderTemplate;
                          context: { parentQuestion: childOfMainQuestion, level: 1 }
                        "
                      ></ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #followUpQuestionExpanderTemplate let-parentQuestion="parentQuestion" let-level="level">
  <ng-container *ngTemplateOutlet="undoIconTemplate; context: { level: level === 1 ? 1 : level }"></ng-container>
  <ng-container *ngFor="let childFollowUp of selectedSection.getChildrenOfParent(parentQuestion); let i = index">
    <ng-container *ngIf="shown[childFollowUp.id]">
      <ng-container
        *ngTemplateOutlet="questionTemplate; context: { question: childFollowUp, level: level + 1 }"
      ></ng-container>
    </ng-container>
    <ng-container *ngIf="shown[childFollowUp.id] && subtreeExpanded[childFollowUp.id]">
      <ng-container
        *ngTemplateOutlet="
          followUpQuestionExpanderTemplate;
          context: { parentQuestion: childFollowUp, level: level + 1 }
        "
      ></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #questionTemplate let-question="question" let-level="level">
  <div
    class="accordion-item mb-0 thin-border-t rounded"
    style="position: relative"
    [ngStyle]="{ 'margin-left': getIndentation(level) }"
    [id]="question.id"
    [ngClass]="
      level > 1 && hasFollowUpQuestions(question)
        ? 'my-0'
        : !hasFollowUpQuestions(question) && level < 4
          ? 'mt-1'
          : 'my-0'
    "
  >
    <h2 class="accordion-header" [id]="'headingFollowUp' + question.id">
      <button
        class="accordion-button collapsed p-2 bg-white"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#collapseFollowUp' + question.id"
        [attr.aria-expanded]="detailExpanded[question.id]"
        [attr.aria-controls]="'collapseFollowUp' + question.id"
        (click)="toggleDetailExpansion(question)"
      >
        <div class="w-100">
          <div class="d-flex flex-row justify-content-between">
            <div class="text-wrap px-2 w-75">
              <h5 class="mt-2 bold" *ngIf="question.followUpCondition">
                If the answer is "{{ selectedSection.ifAnswerIsStringForFollowup(question) }}"
              </h5>
              <h5 class="mt-2">{{ questionsRefNumber.get(question.id) }} {{ question.question }}</h5>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-between">
            <span class="d-flex flex-column flex-lg-row" *ngIf="question.csrdDatapointId">
              <span
                class="badge badge-black-border w-auto py-auto my-auto ms-2 text-nowrap non-collapsing"
                type="button"
                role="button"
                (click)="openCSRDQuestionInfo(question); $event.stopPropagation()"
                data-bs-toggle="collapse"
                data-bs-target
                *ngIf="question.csrdLegislationLocation() && question.csrdLegislationLocation().trim()"
              >
                {{ question.csrdLegislationLocation() }}
              </span>
              <span
                class="w-auto py-auto my-auto ms-2 non-collapsing text-nowrap text-center my-md-1 my-sm-1"
                [ngClass]="
                  isDatapointMaterial(question.csrdDatapointId) ? 'badge-material material' : 'badge-not-material'
                "
              >
                {{ isDatapointMaterial(question.csrdDatapointId) ? 'Material' : 'Not Material' }}
              </span>
              <span
                class="w-auto py-auto my-auto ms-2 non-collapsing text-nowrap badge-material material text-center my-md-1 my-sm-1 my-xs-1"
                *ngIf="csrdDefintionService.getCsrdDatapointSettings(question.csrdDatapointId)?.collectVoluntarily"
              >
                Voluntary
              </span>
              <span
                class="w-auto py-auto my-auto ms-2 non-collapsing text-nowrap badge-not-material text-center my-md-1 my-sm-1"
                *ngIf="csrdDefintionService.getCsrdDatapointSettings(question.csrdDatapointId)?.isOmitted"
              >
                Omitted
              </span>
            </span>
            <div *ngIf="hasFollowUpQuestions(question)">
              <button
                class="btn tertiary-button p-1"
                (click)="selectMainQuestion(question)"
                *ngIf="level == 0 && !selectedMainQuestion"
              >
                {{ slocale('Go to follow up questions') }}
              </button>
              <button
                class="btn tertiary-button p-1"
                (click)="backToMainQuestions()"
                *ngIf="level == 0 && selectedMainQuestion"
              >
                <i class="la la-arrow-left me-1"></i>
                {{ slocale('Back to main questions') }}
              </button>
              <button class="btn tertiary-button p-1" (click)="toggleFollowUpQuestions(question)" *ngIf="level > 0">
                {{
                  subtreeExpanded[question.id]
                    ? slocale('Hide follow up questions')
                    : slocale('Show follow up questions')
                }}
              </button>
            </div>
          </div>
        </div>
      </button>
    </h2>
    <div
      [id]="'collapseFollowUp' + question.id"
      class="accordion-collapse collapse"
      [ngClass]="{ show: detailExpanded[question.id] }"
      [attr.aria-labelledby]="'headingFollowUp' + question.id"
    >
      <div class="accordion-body p-3 mb-2">
        <small class="text-muted multiline">{{ question.desc }}</small>
        <ng-container *ngTemplateOutlet="questionTypeDetailTemplate; context: { question: question }"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #undoIconTemplate let-level="level">
  <div
    [ngStyle]="{
      'margin-left': level === 0 ? '0px' : getIndentation(level),
      'margin-top': '-5px',
      'margin-bottom': '-10px',
      position: 'relative'
    }"
  >
    <i class="la la-undo" style="transform: rotate(180deg)"></i>
  </div>
</ng-template>

<ng-template #questionTypeDetailTemplate let-question="question">
  <div class="d-flex flex-column justify-content-start ps-3 mb-2">
    <div class="col mt-1 mx-0 px-0" *ngIf="requestService.isTextQues(question)">
      <textarea class="form-control w-50" rows="3" placeholder="Write answer" disabled></textarea>
    </div>
    <div class="row w-50" *ngIf="requestService.isEmissionFactorQues(question)">
      <div class="w-50 ms-0 me-4">
        <!-- <emission-factor isDisabled="true"></emission-factor> -->
        <div *ngIf="!containsPredefinedOptions(question)" class="d-flex flex-row ms-0">
          <emission-factor [displayStyle]="'SOURCE_UNIT'" [isDisabled]="true"></emission-factor>
        </div>
        <div *ngIf="containsPredefinedOptions(question)" class="d-flex flex-row ms-0">
          <div class="form-group position-relative ms-0 m-0 p-0">
            <div class="dropdown w-57">
              <button
                class="btn secondary-button btn-block dropdown-toggle m-2 rounded-0 shadow-sm"
                type="button"
                id="dropdownMenuButton"
                data-bs-auto-close="outside"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-display="static"
              >
                {{ locale('locale_key.pages.data_request.create.follow_up_condition.placeholder') }}
              </button>
              <ul class="dropdown-menu wider-dropdown-menu bg-light" aria-labelledby="dropdownMenuButton">
                <li *ngFor="let option of question.predefinedOptions; let i = index">
                  <div class="dropdown-item user-select-none text-wrap">
                    <span class="user-select-none">
                      <i class="la la-fire-alt"></i>
                      <span>
                        {{
                          locale('locale_key.pages.data_request.emissionfactor_display_value', {
                            conversionFactor: option.selectedEmissionFactor?.conversionFactor,
                            conversionUnit: option.selectedEmissionFactor?.conversionUnit,
                            sourceName: option.selectedEmissionFactor?.sourceName
                          })
                        }}
                      </span>
                    </span>
                    <span class="float-end ms-2">
                      <i
                        class="la la-info-circle info-popover-arrow"
                        type="button"
                        (click)="openEfDetailTab(option.selectedEmissionFactor)"
                      ></i>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-50" *ngIf="requestService.isDateQues(question)">
      <div class="col-12 col-6 ms-0 ps-3">
        <!-- <input type="date" class="form-control" placeholder="12/12/2022" disabled /> -->
        <div class="row mt-1 p-0">
          <div class="d-flex flex-wrap align-items-center">
            <fieldset *ngIf="!containsPredefinedOptions(question)" class="form-group position-relative mb-2">
              <input type="date" class="form-control form-control-sm rounded-0" id="specific" disabled />
              <div class="position-absolute top-50 translate-middle-y" style="left: 8rem">
                <i class="la la-calendar font-medium-4"></i>
              </div>
            </fieldset>

            <ng-container *ngIf="question.range && !containsPredefinedOptions(question)">
              <span class="mx-2 mb-2">
                {{ locale('locale_key.pages.data_request.create_question.date.options.range.to') }}
              </span>
              <fieldset class="form-group position-relative me-2 mb-2" style="max-width: 140px">
                <input type="date" class="form-control form-control-sm rounded-0" id="range" disabled />
                <div class="position-absolute top-50 translate-middle-y" style="left: 8rem">
                  <i class="la la-calendar font-medium-4"></i>
                </div>
              </fieldset>
            </ng-container>

            <div class="d-flex align-items-center mb-2">
              <ng-container *ngIf="containsPredefinedOptions(question)" class="form-group me-2">
                <div class="dropdown">
                  <button
                    class="btn secondary-button btn-block dropdown-toggle m-2 rounded-0 shadow-sm"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-auto-close="outside"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-display="static"
                  >
                    {{ locale('locale_key.pages.data_request.create.follow_up_condition.placeholder') }}
                  </button>
                  <ul
                    class="dropdown-menu bg-light"
                    aria-labelledby="dropdownMenuButton"
                    [ngClass]="question.range ? 'wider-dropdown-menu' : 'wide-dropdown-menu'"
                  >
                    <li *ngFor="let option of getSortedPredfinedDateOptions(question); let i = index">
                      <span *ngIf="!question.range" class="dropdown-item">
                        {{ option.from | date: 'dd MMM, yyyy' }}
                      </span>
                      <span *ngIf="question.range" class="dropdown-item">
                        {{ option.from | date: 'dd MMM, yyyy' }} {{ locale('locale_key.general.email.to') }}
                        {{ option.to | date: 'dd MMM, yyyy' }}
                      </span>
                    </li>
                  </ul>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-50" *ngIf="requestService.isNumberQues(question)">
      <div class="col-12 ms-0 ps-3">
        <!-- <input type="text" class="form-cntrol" [placeholder]="locale('locale_key.pages.data_request.create.question.value.placeholder')" disabled /> -->
        <div *ngIf="!containsPredefinedOptions(question)" class="d-flex flex-row ms-0">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="flex-grow-1 me-2 pt-0 w-50">
              <input
                class="form-control rounded-0 h-40"
                type="text"
                placeholder="{{ locale('locale_key.pages.data_request.create.question.value.placeholder') }}"
                disabled
              />
            </div>
            <div class="flex-grow-1 pt-0 h-40 w-50">
              <div class="dropdown w-100">
                <button
                  class="form-control disabled rounded-0 d-flex align-items-center justify-content-between h-40 w-100"
                  type="button"
                  id="dropdownMenuButton"
                  aria-expanded="false"
                  disabled
                >
                  <span>{{ locale('locale_key.pages.unit-selector.select_unit') }}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="12"
                    viewBox="0 0 11 12"
                    fill="none"
                    class="ms-2"
                  >
                    <path
                      d="M1.375 3.9375L5.5 8.0625L9.625 3.9375"
                      stroke="#6B6E80"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="containsPredefinedOptions(question)" class="d-flex flex-row ms-0">
          <div class="d-flex flex-row align-items-center p-0">
            <div
              *ngIf="question.predefinedOptions[0].value == null"
              class="d-flex align-items-center justify-content-between"
            >
              <input
                type="text"
                class="form-control mb-1 rounded-0 me-2 flex-grow-1 h-40"
                placeholder="{{ locale('locale_key.pages.data_request.create.question.value.placeholder') }}"
                disabled
              />
              <input
                type="text"
                class="form-control mb-1 rounded-0 me-2 flex-grow-1 h-40"
                value="{{ getUnit(question.predefinedOptions[0]) }}"
                disabled
              />
            </div>

            <ng-container *ngIf="containsPredefinedOptions(question) && question.predefinedOptions[0].value != null">
              <div class="form-group position-relative ms-0 m-0 p-0">
                <div class="dropdown">
                  <button
                    class="btn secondary-button btn-block dropdown-toggle m-2 rounded-0 shadow-sm"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-auto-close="outside"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-display="static"
                  >
                    {{ locale('locale_key.pages.data_request.create.follow_up_condition.placeholder') }}
                  </button>
                  <ul class="dropdown-menu wider-dropdown-menu bg-light" aria-labelledby="dropdownMenuButton">
                    <li *ngFor="let option of getSortedPredfinedNumberOptions(question); let i = index">
                      <span class="dropdown-item">{{ option.value }} {{ getUnit(option) }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-6 ms-0 ps-3">
        <p>{{ question.unit?.symbol }}</p>
      </div>
    </div>
    <div class="row w-50" *ngIf="requestService.isAttachmentQues(question)">
      <div class="col-12 m-1 ps-3">
        <button class="btn primary-button" disabled>
          {{ locale('locale_key.pages.data_request.review_answer_wizard.file.button.select_file') }}
        </button>
      </div>
    </div>
    <div class="row" *ngIf="requestService.isDataTableQues(question)">
      <!--Form View starts-->
      <div class="mx-0 mt-3">
        <div class="d-flex flex-lg-row flex-column align-items-center justify-content-between">
          <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12 m-0 p-0">
            <div class="nav nav-tabs px-1" style="width: 200px; position: sticky; top: 0; border: none">
              <ul
                class="nav nav-underline nav-tabs no-hover-bg nav-justified"
                style="width: 100%; border-bottom: none !important"
              >
                <li class="nav-item">
                  <a
                    class="nav-link"
                    [ngClass]="{ active: !isFormView }"
                    id="tableView-tab"
                    data-bs-toggle="tab"
                    href="#view"
                    aria-expanded="true"
                    (click)="setTableView()"
                  >
                    {{ slocale('Table View') }}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    [ngClass]="{ active: isFormView }"
                    id="formView-tab"
                    data-bs-toggle="tab"
                    href="#view"
                    aria-expanded="false"
                    (click)="setFormView()"
                  >
                    {{ slocale('Form View') }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12 m-0 p-0">
            <div
              class="d-flex flex-row my-3 h-100 w-fit-content orange-border p-2"
              [ngClass]="screenSize.isLargeSize() || screenSize.isXLargeSize() ? 'ms-auto' : 'me-auto'"
              *ngIf="false"
            >
              <span class="">{{ slocale('Reporters can add additional rows ') }}</span>
              <i
                class="la la-info-circle dropdown-toggle info-popover-arrow fs-5 mx-2"
                type="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style="position: relative; top: 3px"
              ></i>
              <div class="dropdown-menu arrow p-2 info-popover">
                <p>{{ slocale('If this option is enabled, data reporters will be able to add new rows.') }}</p>
              </div>
              <label class="switch text-end">
                <input type="checkbox" [(ngModel)]="question.required" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
        <div
          class="tab-content w-100"
          [ngClass]="screenSize.isXLargeSize() || screenSize.isLargeSize() ? 'px-2' : 'px-0'"
        >
          <div role="tabpanel" class="tab-pane active" id="view" aria-expanded="true">
            <div id="question" *ngIf="requestService.isDataTableQues(question)">
              <div
                class="row m-0 p-0"
                [ngClass]="{ 'table-view': !isFormView, 'form-view': isFormView }"
                style="width: 100% !important; margin: 0 !important; padding: 0 !important"
              >
                <datagrid-table-editor
                  *ngIf="!question.editMapping"
                  [dataGrid]="question.requestItemDataGridService"
                  [isFormView]="isFormView"
                  [isSMView]="true"
                  [isPreviewMode]="true"
                  style="width: 100% !important; margin: 0 !important; padding: 0 !important"
                ></datagrid-table-editor>
                <div class="ms-0 my-1" *ngIf="!isFormView">
                  <button class="btn primary-button" disabled>
                    {{ locale('locale_key.general.buttons.extract_from_file') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Form View ends-->
    </div>
    <div *ngIf="requestService.isMultipleChoiceQues(question)">
      <div class="row me-auto mx-1" *ngFor="let option of question['options']">
        <a id="btnMenu-11" class="dropdown" (click)="option.showDesc = !option.showDesc">
          <input type="checkbox" class="me-3 d-inline mp-50" style="position: relative; bottom: 1px" disabled />
          <p class="d-inline">{{ option.value }}</p>
          <i class="ms-2 la la-angle-down d-inline" id="chev-1" *ngIf="option.desc && !option.showDesc"></i>
          <i class="ms-2 la la-angle-up d-inline" id="chev-1" *ngIf="option.desc && option.showDesc"></i>
        </a>
        <div
          id="menu-1"
          class="dropdown-menu position-relative dropdown-demo px-1"
          [ngClass]="{ 'show mb-2': option.showDesc, 'border-0': option.showDesc }"
        >
          {{ option.desc }}
        </div>
      </div>
    </div>
    <div *ngIf="requestService.isSingleChoiceQues(question)">
      <div class="row me-auto mx-1" *ngFor="let option of question['options']">
        <a id="btnMenu-11" class="dropdown" (click)="option.showDesc = !option.showDesc">
          <input
            type="radio"
            class="me-3 d-inline mp-50"
            style="position: relative; bottom: 1px"
            [value]="option.id"
            disabled
          />
          <p class="d-inline">{{ option.value }}</p>
          <i class="ms-2 la la-angle-down d-inline" id="chev-1" *ngIf="option.desc && !option.showDesc"></i>
          <i class="ms-2 la la-angle-up d-inline" id="chev-1" *ngIf="option.desc && option.showDesc"></i>
        </a>
        <div
          id="menu-1"
          class="dropdown-menu position-relative dropdown-demo px-1"
          [ngClass]="{ 'show mb-2': option.showDesc, 'border-0': option.showDesc }"
        >
          {{ option.desc }}
        </div>
      </div>
    </div>
    <div class="col mt-1 ms-0 ps-0" *ngIf="question.comments">
      <label class="mt-1">{{ locale('locale_key.modal.questionnaire_preview.comments') }}</label>
      <textarea class="form-control w-50" rows="1" disabled></textarea>
    </div>
  </div>
</ng-template>

import { DOCUMENT } from '@angular/common'
import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core'
import { BsModalService } from 'ngx-bootstrap/modal'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { CSRD_PROJECT_PAGES } from '../../csrd-project-overview.component'
import { CSRDEsrsSettings, CSRDProjectInfoFe, CSRDTopicSettings } from 'src/app/model/project/CSRDProjectInfoFe'
import _ from 'lodash'
import { PROJECTS_PAGES } from 'src/app/components/projects/projects.component'

enum LEAVE_PAGE_SOURCES {
  'BACK' = 'BACK',
  'ALL_PROJECTS' = 'ALL_PROJECTS'
}

@Component({
  selector: 'csrd-define-materiality',
  templateUrl: './define-materiality.component.html',
  styleUrls: ['./define-materiality.component.scss']
})
export class DefineMaterialityComponent extends AbstractLanguageComponent implements OnInit {
  filter(arg0: any) {
    throw new Error('Method not implemented.')
  }
  collapseAll() {
    throw new Error('Method not implemented.')
  }
  expandAll() {
    throw new Error('Method not implemented.')
  }

  @ViewChild('addProjectModal') addProjectModal: TemplateRef<any>
  @ViewChild('saveDraft') saveDraft: TemplateRef<any>
  @ViewChild('cancelDefineMaterialityModal') cancelDefineMaterialityModal: TemplateRef<any>
  @ViewChild('confirmMaterialityModal') confirmMaterialityModal: TemplateRef<any>
  @ViewChild('createSubTopicModal') createSubTopicModal: TemplateRef<any>
  @ViewChild('confirmNotMaterialModal') confirmNotMaterialModal: TemplateRef<any>
  @ViewChild('logoutInactivityModal') logoutInactivityModal: TemplateRef<any>
  @ViewChild('exportTableModal') exportTableModal: TemplateRef<any>

  loadingInProgress: boolean

  url: string = ''
  initCacheInProgress: boolean
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  loadingData: boolean = false

  activeFormNo = 1
  progress: HTMLElement | undefined
  circles: any
  prevButton: HTMLButtonElement | undefined
  nextButton: HTMLButtonElement | undefined
  currentActive: number = 1
  inProgress = true
  projectName = ''
  cardHeader: boolean = false
  cardFooter: boolean = true
  selectedOption: string = ''
  materiality: boolean = true

  tabNames = {
    tab31: 'All data entries',
    tab32: 'Per emission source',
    tab33: 'Per organizational unit',
    tab34: 'Request overview'
  }

  pageToolbar = [
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.back'),
        longLabel: this.locale('locale_key.general.toolbar.button.back'),
        tooltip: this.locale('locale_key.general.toolbar.button.back'),
        icon: 'la la-arrow-left',
        actionName: 'back',
        visible: () => true
      }
    ],
    [
      {
        shortLabel: this.slocale('All projects'),
        longLabel: this.slocale('All projects'),
        tooltip: this.slocale('All projects'),
        icon: 'la la-project-diagram',
        visible: () => true,
        actionName: 'all_projects'
      }
    ]
  ]

  @Input() activeCsrdProject: CSRDProjectInfoFe
  @Input() activeTopic: any
  @Input() activeEsrs: any
  @Output() switchProjectsPage = new EventEmitter<PROJECTS_PAGES>()
  @Output() switchCsrdProjectPage = new EventEmitter<CSRD_PROJECT_PAGES>()
  isAssessed: boolean = false
  isMaterial: boolean = false
  confirmingMateriality: boolean = false
  subtopics: any[] = []
  subsubtopics: any[] = []

  @ViewChild('saveBeforeLeavingModal') saveBeforeLeavingModal: TemplateRef<any>
  leavePageSource: LEAVE_PAGE_SOURCES
  changesMade = false

  constructor(
    private modalService: BsModalService,
    @Inject(DOCUMENT) private _document: Document,
    public stateService: StateServiceFe,
    public displayService: DisplayServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService
  ) {
    super(languageService)
    this.url = window.location.href
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    })

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    this.screenSize = responsive.currentScreenWidthSize
  }

  ngOnInit(): void {
    this.isAssessed = this.activeEsrs.isAssessed
    this.isMaterial = this.activeEsrs.isMaterial
    const { newTopicSettings, newEsrsSettings } = this.getNewTopicAndEsrsSettings()

    const selectedSubTopics = newEsrsSettings.selectedSubTopics
    this.subtopics = this.activeEsrs.subTopics.map((name) => {
      const subtopic = { name, isSelected: false }
      // Determine if subtopic is selected from checking active project
      subtopic.isSelected = false
      if (selectedSubTopics.includes(name)) {
        subtopic.isSelected = true
      }
      return subtopic
    })

    const selectedSubSubTopics = newEsrsSettings.selectedSubSubTopics
    this.subsubtopics = this.activeEsrs.subSubTopics.map((name) => {
      const subsubtopic = { name, isSelected: false }
      // Determine if subsubtopic is selected from checking active project
      subsubtopic.isSelected = false
      if (selectedSubSubTopics.includes(name)) {
        subsubtopic.isSelected = true
      }
      return subsubtopic
    })
  }

  toggleTips() {
    this.displayService.toggleTips()
  }

  openModal(templateRef: TemplateRef<any>, size: string = '') {
    this.modalService.show(templateRef, { class: size })
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case 'back':
        if (this.changesMade) {
          this.leavePageSource = LEAVE_PAGE_SOURCES.BACK
          this.openModal(this.saveBeforeLeavingModal, 'modal-md')
        } else {
          this.switchCsrdProjectPage.emit(CSRD_PROJECT_PAGES.CSRD_PROJECT_OVERVIEW)
        }

        break
      case 'all_projects':
        if (this.changesMade) {
          this.leavePageSource = LEAVE_PAGE_SOURCES.ALL_PROJECTS
          this.openModal(this.saveBeforeLeavingModal, 'modal-md')
        } else {
          this.switchProjectsPage.emit(PROJECTS_PAGES.VIEW_PROJECTS)
          this.activeCsrdProject = null
        }
        break
      case 'export_table':
        this.openModal(this.exportTableModal, 'modal-md')
        break
      case 'toggle_quick_tips':
        this.toggleTips()
        break
    }
  }

  update() {
    this.circles = this._document.querySelectorAll('.circle1')

    this.circles.forEach((circle: any, index: number) => {
      if (index < this.currentActive) {
        circle.classList.add('active-circle1')
      } else {
        circle.classList.remove('active-circle1')
      }
    })

    const actives = this._document.querySelectorAll('.active-circle1')
  }

  next() {
    // if (this.activeFormNo == 1) {
    //   this.reqDetailsForm.markAllAsTouched();
    //   if (this.reqDetailsForm.invalid) {
    //     return;
    //   }
    // } else {
    //   this.validateQuestionnaire();
    //   if (this.isQuestionnaireInvalid) {
    //     return;
    //   }
    // }
    this.currentActive++
    this.activeFormNo++
    this.update()
  }

  prev(): void {
    this.currentActive--
    if (this.currentActive < 1) {
      this.currentActive = 1
    }
    this.activeFormNo--
    this.update()
  }

  closeModal() {
    this.modalService.hide()
  }

  saveDraftModal(saveDraft: boolean) {
    this.modalService.show(this.saveDraft, { class: 'modal-md' })
  }

  saveProject() {
    this.modalService.hide()
  }

  editCalculation(id: string) {}

  setDateOption(option: string) {
    this.selectedOption = option
  }

  startCancelDefineMateriality() {
    this.modalService.show(this.cancelDefineMaterialityModal, { class: 'modal-md' })
  }

  confirmCancelDefineMateriality() {
    this.closeModal()
    this.switchCsrdProjectPage.emit(CSRD_PROJECT_PAGES.CSRD_PROJECT_OVERVIEW)
  }

  setMateriality(materiality: boolean) {
    this.changesMade = true
    this.isAssessed = true
    this.isMaterial = materiality
  }

  startConfirmMateriality() {
    this.continueConfirmMateriality()
  }

  async continueConfirmMateriality() {
    this.confirmingMateriality = true
    //update activeEsrs
    this.activeEsrs.isAssessed = this.isAssessed
    this.activeEsrs.isMaterial = this.isMaterial

    //update activeCsrdProject

    const { newTopicSettings, newEsrsSettings } = this.getNewTopicAndEsrsSettings()

    newEsrsSettings.isAssessed = true

    newEsrsSettings.isMaterial = !!this.isMaterial

    this.updateNewTopicAndEsrsSettings({ newTopicSettings, newEsrsSettings })

    //save activeCsrdProject
    await this.stateService.updateCsrdProject(this.activeCsrdProject)

    // Update active esrs
    this.activeEsrs.selectedSubTopics = newEsrsSettings.selectedSubTopics
    this.activeEsrs.selectedSubSubTopics = newEsrsSettings.selectedSubSubTopics

    //switch back to project overview
    this.closeModal()
    this.switchCsrdProjectPage.emit(CSRD_PROJECT_PAGES.CSRD_PROJECT_OVERVIEW)
    this.confirmingMateriality = true
  }

  selectSubtopic(subtopic) {
    this.changesMade = true
    subtopic.isSelected = true
    const { newTopicSettings, newEsrsSettings } = this.getNewTopicAndEsrsSettings()
    newEsrsSettings.selectedSubTopics.push(subtopic.name)
    newEsrsSettings.selectedSubTopics = _.uniq(newEsrsSettings.selectedSubTopics)
    this.updateNewTopicAndEsrsSettings({ newTopicSettings, newEsrsSettings })
  }

  selectAllSubtopics() {
    this.changesMade = true
    const { newTopicSettings, newEsrsSettings } = this.getNewTopicAndEsrsSettings()
    this.subtopics = this.subtopics.map((subtopic) => {
      subtopic.isSelected = true
      newEsrsSettings.selectedSubTopics.push(subtopic.name)
      return subtopic
    })
    newEsrsSettings.selectedSubTopics = _.uniq(newEsrsSettings.selectedSubTopics)
    this.updateNewTopicAndEsrsSettings({ newTopicSettings, newEsrsSettings })
  }

  deselectSubtopic(subtopic) {
    this.changesMade = true
    subtopic.isSelected = false
    const { newTopicSettings, newEsrsSettings } = this.getNewTopicAndEsrsSettings()
    newEsrsSettings.selectedSubTopics = newEsrsSettings.selectedSubTopics.filter(
      (subtopicName) => subtopicName != subtopic.name
    )
    this.updateNewTopicAndEsrsSettings({ newTopicSettings, newEsrsSettings })
  }

  deselectAllSubtopics() {
    this.changesMade = true
    const { newTopicSettings, newEsrsSettings } = this.getNewTopicAndEsrsSettings()
    this.subtopics = this.subtopics.map((subtopic) => {
      subtopic.isSelected = false
      return subtopic
    })
    newEsrsSettings.selectedSubTopics = []
    this.updateNewTopicAndEsrsSettings({ newTopicSettings, newEsrsSettings })
  }

  selectSubSubtopic(subsubtopic) {
    this.changesMade = true
    subsubtopic.isSelected = true
    const { newTopicSettings, newEsrsSettings } = this.getNewTopicAndEsrsSettings()
    newEsrsSettings.selectedSubSubTopics.push(subsubtopic.name)
    newEsrsSettings.selectedSubSubTopics = _.uniq(newEsrsSettings.selectedSubSubTopics)
    this.updateNewTopicAndEsrsSettings({ newTopicSettings, newEsrsSettings })
  }

  selectAllSubSubtopics() {
    this.changesMade = true
    const { newTopicSettings, newEsrsSettings } = this.getNewTopicAndEsrsSettings()

    this.subsubtopics = this.subsubtopics.map((subsubtopic) => {
      subsubtopic.isSelected = true
      newEsrsSettings.selectedSubSubTopics.push(subsubtopic.name)
      return subsubtopic
    })

    newEsrsSettings.selectedSubSubTopics = _.uniq(newEsrsSettings.selectedSubSubTopics)
    this.updateNewTopicAndEsrsSettings({ newTopicSettings, newEsrsSettings })
  }

  deselectSubSubtopic(subsubtopic) {
    this.changesMade = true
    const { newTopicSettings, newEsrsSettings } = this.getNewTopicAndEsrsSettings()
    subsubtopic.isSelected = false
    newEsrsSettings.selectedSubSubTopics = newEsrsSettings.selectedSubSubTopics.filter(
      (subsubtopicName) => subsubtopicName != subsubtopic.name
    )
    this.updateNewTopicAndEsrsSettings({ newTopicSettings, newEsrsSettings })
  }

  deselectAllSubSubtopics() {
    this.changesMade = true
    const { newTopicSettings, newEsrsSettings } = this.getNewTopicAndEsrsSettings()

    this.subsubtopics = this.subsubtopics.map((subsubtopic) => {
      subsubtopic.isSelected = false
      return subsubtopic
    })

    newEsrsSettings.selectedSubSubTopics = []
    this.updateNewTopicAndEsrsSettings({ newTopicSettings, newEsrsSettings })
  }

  getNewTopicAndEsrsSettings(): { newTopicSettings: CSRDTopicSettings; newEsrsSettings: CSRDEsrsSettings } {
    let newTopicSettings: CSRDTopicSettings
    this.activeCsrdProject.definition.csrdSettings.forEach((topicSettings) => {
      if (topicSettings.id == this.activeTopic.id) {
        newTopicSettings = _.cloneDeep(topicSettings)
      }
    })

    if (_.isEmpty(newTopicSettings)) {
      newTopicSettings = {
        id: this.activeTopic.id,
        esrs: []
      }
    }

    let newEsrsSettings: CSRDEsrsSettings
    newTopicSettings.esrs.forEach((esrsSettings) => {
      if (esrsSettings.id == this.activeEsrs.id) {
        newEsrsSettings = _.cloneDeep(esrsSettings)
      }
    })

    if (_.isEmpty(newEsrsSettings)) {
      newEsrsSettings = {
        id: this.activeEsrs.id,
        isAssessed: false,
        isMaterial: false,
        isMandatory: this.activeEsrs.isMandatory,
        disclosures: [],
        selectedSubSubTopics: [],
        selectedSubTopics: []
      }
    }

    return { newTopicSettings, newEsrsSettings }
  }

  updateNewTopicAndEsrsSettings({
    newTopicSettings,
    newEsrsSettings
  }: {
    newTopicSettings: CSRDTopicSettings
    newEsrsSettings: CSRDEsrsSettings
  }) {
    let existingEsrsFound = false
    newTopicSettings.esrs = newTopicSettings.esrs.map((esrsSettings) => {
      if (esrsSettings.id == newEsrsSettings.id) {
        existingEsrsFound = true
        return newEsrsSettings
      }
      return esrsSettings
    })
    if (!existingEsrsFound) {
      newTopicSettings.esrs.push(newEsrsSettings)
    }

    let existingTopicFound = false
    this.activeCsrdProject.definition.csrdSettings = this.activeCsrdProject.definition.csrdSettings.map(
      (topicSettings) => {
        if (topicSettings.id == newTopicSettings.id) {
          existingTopicFound = true
          return newTopicSettings
        }
        return topicSettings
      }
    )
    if (!existingTopicFound) {
      this.activeCsrdProject.definition.csrdSettings.push(newTopicSettings)
    }
  }

  dontLeavePage() {
    this.closeModal()
  }

  leavePage() {
    switch (this.leavePageSource) {
      case LEAVE_PAGE_SOURCES.BACK:
        this.switchCsrdProjectPage.emit(CSRD_PROJECT_PAGES.CSRD_PROJECT_OVERVIEW)
        this.closeModal()
        break
      case LEAVE_PAGE_SOURCES.ALL_PROJECTS:
        this.switchProjectsPage.emit(PROJECTS_PAGES.VIEW_PROJECTS)
        this.activeCsrdProject = null
        this.closeModal()
        break
    }
  }
}

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { REQUEST_TASK_STATUS } from 'src/app/model/data-suppliers/request/RequestFe'
import { TaskFe, TASK_STATUS } from 'src/app/model/data-suppliers/task/TaskFe'
import { AbstractActivityFe } from 'src/app/model/data-suppliers/timeline/AbstractActivityFe'
import { AcceptSubmissionActivityFe } from 'src/app/model/data-suppliers/timeline/AcceptSubmissionActivityFe'
import { ActivityTypeFe } from 'src/app/model/data-suppliers/timeline/ActivityTypeFe'
import { CloseRequestActivityFe } from 'src/app/model/data-suppliers/timeline/CloseRequestActivityFe'
import { CreateRequestActivityFe } from 'src/app/model/data-suppliers/timeline/CreateRequestActivityFe'
import { RejectSubmissionActivityFe } from 'src/app/model/data-suppliers/timeline/RejectSubmissionActivityFe'
import { SubmitAnswersActivityFe } from 'src/app/model/data-suppliers/timeline/SubmitAnswersActivityFe'
import { SubmitMessageActivityFe } from 'src/app/model/data-suppliers/timeline/SubmitMessageActivityFe'
import { FileDataFe } from 'src/app/model/file/FileDataFe'
import { RowFe } from 'src/app/model/schema/RowFe'
import { RowStatusFe } from 'src/app/model/schema/RowStatusFe'
import { TableDataFe } from 'src/app/model/schema/TableDataFe'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { TableStorageServiceFe } from 'src/app/services/TableStorageServiceFe'
import { DateUtil } from 'src/app/utils/DateUtil'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { TaskDataGridIntegrationService } from '../../data-suppliers/data-supplier-task/TaskDataGridIntegrationService'
import { AnswerComponent } from '../answer/answer.component'
import { ISaveAnswersCanDeactivate } from 'src/app/routeGuard/save-answers.guard'
import { Observable } from 'rxjs'
import { Router } from '@angular/router'

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss', '../../common/data-suppliers.component.scss']
})
export class DORequestsComponent extends AbstractLanguageComponent implements OnInit, ISaveAnswersCanDeactivate {
  @ViewChild(AnswerComponent) answerComponent!: AnswerComponent

  isCustomModalOpen: boolean = false
  tasks: TaskFe[] = []
  selectedTask: TaskFe | null
  viewNo = 1
  viewType = 'inbox'
  taskDataGridService = new TaskDataGridIntegrationService(new TableDataFe())
  activityDataGridService: { [key: string]: TaskDataGridIntegrationService } = {}
  selectedTaskIndex: number = -1

  rightModalTitleTask = 'Task info'
  detailsData: any
  hideAllTasks = false
  selectedIndex: number
  status: boolean = false
  loadingInfo = false
  loadingInProgress = false
  draftLoadingInProgress = false
  progress = 0

  inProgress = true
  activityInbox: boolean = false
  dataRequest: boolean = true
  tabTitle = 'Request Portal'
  slideOpen: boolean = false
  slideDoneOpen: boolean = false
  slideHistoryOpen: boolean = false
  slideCompletedOpen: boolean = true
  selectedActivity: AbstractActivityFe
  editSettings = false
  selectedActivityIndex: number = -1
  selectedDoneActivityIndex: number = -1
  unreadSelected: boolean = false
  requestGroups = []

  answerMode = false
  url = ''
  initCacheInProgress: boolean
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  isLoading: boolean = false
  pageToolbar = [
    ,
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.back'),
        longLabel: this.locale('locale_key.general.toolbar.button.back'),
        tooltip: this.locale('locale_key.general.toolbar.button.back'),
        icon: 'la la-arrow-left fs-4',
        actionName: 'back_button',
        visible: () => this.viewNo === 2,
        disabled: () => this.loadingInfo || this.loadingInProgress
      }
    ],
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.info'),
        longLabel: this.locale('locale_key.general.toolbar.button.show_quick_tips'),
        tooltip: this.locale('locale_key.general.toolbar.button.show_quick_tips'),
        icon: 'la la-info',
        actionName: 'toggle_quick_tips',
        visible: () => true,
        disabled: false
      }
    ]
  ]

  //FOR FILTERING
  searchKeyword: string = ''
  filteredTasks: TaskFe[] = []
  sortedBy = null
  sortedByOptions = [
    { name: this.locale('locale_key.pages.datahub.sort.option.created_date'), value: 'createdDate', divider: true },
    { name: this.locale('locale_key.pages.datahub.sort.option.created_date_desc'), value: 'createdDateDes' },
    { name: this.locale('locale_key.pages.datahub.sort.option.deadline'), value: 'deadline', divider: true },
    { name: this.locale('locale_key.pages.datahub.sort.option.deadline_desc'), value: 'deadlineDes' },
    { name: this.locale('locale_key.pages.datahub.sort.option.name'), value: 'name', divider: true },
    { name: this.locale('locale_key.pages.datahub.sort.option.name_desc'), value: 'nameDes' }
  ]

  selectedStatus = null
  customMultipleFilters: { name: string; value: string; type: 'SM' | 'COMPANY' }[] = []
  filteredCompanies: { companyId: string; name: string }[] = []
  filteredSMs: { affId: string; name: string }[] = []
  pendingNavigationUrl: string
  shouldBackButtonBeTriggered: boolean = false

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    public stateService: StateServiceFe,
    private displayService: DisplayServiceFe,
    public tableStorageService: TableStorageServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService,
    private router: Router
  ) {
    super(languageService)
    this.url = window.location.href
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    })

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
    this.screenSize = responsive.currentScreenWidthSize
  }

  async ngOnInit(): Promise<void> {
    await this.renderNewData()

    this.viewNo = 1
    if (this.displayService.dataFromHomePage) {
      let activity = this.displayService.dataFromHomePage
      let task = this.tasks.find((rg) => rg.id == activity.requestId)
      if (task) {
        this.selectTask(task)
      }
      this.displayService.dataFromHomePage = null
    }

    this.displayService.dataFromDetailsSubject.subscribe((isUpdated) => {
      if (isUpdated) {
        const data = this.displayService.dataFromDetails
      }
    })

    this.stateService.taskTimelineItemsUpdatedSubject.subscribe((newActivity) => {
      if (!this.selectedTask) return

      let index = this.selectedTask.timelineItems.findIndex((act) => act.id == newActivity.id)
      this.selectedTask.timelineItems[index] = newActivity
    })
    this.pendingNavigationUrl = null
  }

  handleLeavingQuestionnaire() {
    if (this.shouldBackButtonBeTriggered) {
      this.handleBackButton()
      return
    }

    this.handleNavigationUrl()
  }

  handleNavigationUrl() {
    if (!this.pendingNavigationUrl) return

    this.router.navigate([this.pendingNavigationUrl])
    this.pendingNavigationUrl = null
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.answerComponent) return true

    const isAutoSaveComplete = this.answerComponent.isQuestionnaireAutoSaveCompleted()
    const nextUrl = this.router.getCurrentNavigation()?.finalUrl?.toString()
    if (!isAutoSaveComplete) {
      this.pendingNavigationUrl = nextUrl

      return false
    }
    this.pendingNavigationUrl = null

    return true
  }

  async renderNewData() {
    this.tasks = await this.stateService.getTasks()
    this.requestGroups = await this.stateService.getRequestGroups()
    this.tasks.forEach((t) => (t.requestGroup = this.requestGroups.find((rg) => rg.id == t.requestGroupId)))
    this.applyFilters()
    this.filterCompanies()
    this.filterSMs()
  }

  public openModal(modalTemplateRef: TemplateRef<any>, clazz: string = 'modal-md') {
    let config = {
      backdrop: false,
      ignoreBackdropClick: false,
      class: clazz
    }
    this.modalRef = this.modalService.show(modalTemplateRef, config)
  }

  closeModal(close: boolean) {
    if (close) {
      this.modalService.hide(this.modalRef.id)
      document.body.classList.remove('modal-open')
    }
  }

  setTaskRequestIten(index: number) {
    if (this.selectedTask.status != TASK_STATUS.CLOSED) {
      // TODO
      // this.selectedRequestItem = this.selectedTask.requestItems[index]
      this.selectedIndex = index
      this.toggleTasks()
    }
  }

  isSubmitAnswer(activity: AbstractActivityFe) {
    return activity instanceof SubmitAnswersActivityFe
  }

  isMessage(activity: AbstractActivityFe) {
    return activity instanceof SubmitMessageActivityFe
  }

  isAccept(activity: AbstractActivityFe) {
    return activity instanceof AcceptSubmissionActivityFe
  }

  isReject(activity: AbstractActivityFe) {
    return activity instanceof RejectSubmissionActivityFe
  }

  isCloseRequest(activity: AbstractActivityFe) {
    return activity instanceof CloseRequestActivityFe
  }

  isCreateRequest(activity: AbstractActivityFe) {
    return activity instanceof CreateRequestActivityFe
  }

  selectTask(task: TaskFe) {
    this.selectedTask = task
    this.viewNo++
    this.taskDataGridService.table = new TableDataFe()
  }

  updateDetailsData() {
    this.detailsData = {
      task: this.selectedTask
    }
    this.displayService.updateDetailsData(this.detailsData)
    this.displayService.updateQuickTipsData({ page: 'tasks' })
  }

  selectedFile: FileDataFe

  fileSelected(file: FileDataFe) {
    this.selectedFile = file
  }

  async dataExtracted(newTableData: TableDataFe) {
    newTableData.rows.forEach((row) => (row.status = RowStatusFe.ADDED))
    const newRows = []
    this.taskDataGridService.observer
      .getModifiedTableData()
      .rows.forEach((row) => newRows.push(RowFe.fromDataGridRow(row)))
    this.taskDataGridService.table.rows = newRows.concat(newTableData.rows)
    this.taskDataGridService.table.rowCount = this.taskDataGridService.table.rows.length
    this.taskDataGridService.markDataModified(newTableData.rows.length > 0, true)
    await this.taskDataGridService.observer.renderNewDataTable()
    this.closeModal(true)
  }

  toggleTips() {
    this.displayService.toggleTips()
  }

  getDeadline(task: TaskFe) {
    return DateUtil.getDeadlineClass(new Date(task.dueDate), task.status)
  }

  toggleTasks() {
    let index = this.selectedIndex
    let listOfTasks = document.getElementsByClassName('item')
    Array.from(listOfTasks)
      .filter((item) => !item.classList.contains('item' + index))
      .forEach((item) => {
        if (item.classList.contains('hide')) {
          item.classList.remove('hide')
        } else {
          item.classList.add('hide')
        }
      })
    this.status = !this.status
  }

  closeAttachments() {
    this.displayService.closeAttachments()
  }

  handleBackButton() {
    this.shouldBackButtonBeTriggered = false

    if (!this.answerComponent || this.answerComponent.viewNo == 1) {
      this.viewNo = 1

      return
    }

    if (this.answerComponent.viewNo == 3) {
      this.answerComponent.viewNo = 2

      return
    }

    const isAutoSaveComplete = this.answerComponent.isQuestionnaireAutoSaveCompleted()
    if (!isAutoSaveComplete) {
      this.shouldBackButtonBeTriggered = true
      return
    }

    this.answerComponent.viewNo = 1
  }

  isActionRequired(activity: AbstractActivityFe) {
    let task = this.tasks.find((t) => t.id == activity.requestId)
    if (activity instanceof CreateRequestActivityFe) {
      return task.status == REQUEST_TASK_STATUS.CREATED
    } else if (activity instanceof RejectSubmissionActivityFe) {
      let act = task.timelineItems
        .slice()
        .reverse()
        .find((a) => a.id == activity.id || a instanceof SubmitAnswersActivityFe || a instanceof CloseRequestActivityFe)
      if (act instanceof SubmitAnswersActivityFe || act instanceof CloseRequestActivityFe) return false
      else if (act instanceof RejectSubmissionActivityFe) return true
    }
  }

  noAnswersSubmitted(activity: AbstractActivityFe) {
    let isSubmitted = this.selectedTask.timelineItems.some((a) => a.type == ActivityTypeFe.SUBMIT_ANSWERS)
    return !isSubmitted
  }

  isLatestSubmission(activity: AbstractActivityFe, task = this.selectedTask): boolean {
    let acts = task.timelineItems.slice().reverse()
    let a = acts.find((a) => this.isSubmitAnswer(a))
    return activity.id == a?.id
  }

  startNewAnswer() {
    this.answerMode = true
  }

  endAnswerMode() {
    this.answerMode = false
    this.viewNo = 1
  }

  noCompletedTasks() {
    return (
      this.tasks.filter((t) => t.status == REQUEST_TASK_STATUS.APPROVED || t.status == REQUEST_TASK_STATUS.CLOSED)
        .length == 0
    )
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case 'toggle_quick_tips':
        this.toggleTips()
        break
      case 'back_button':
        this.handleBackButton()
        break
    }
  }

  openMessageModal() {
    this.isCustomModalOpen = !this.isCustomModalOpen
  }

  applyFilters() {
    this.applyShowOnlyFilter()
  }

  async applyShowOnlyFilter() {
    this.tasks = await this.stateService.getTasks()
    if (this.selectedStatus) {
      this.tasks = this.tasks.filter((f) => f.status == this.selectedStatus)
    }
    this.applyCustomFilters()
  }

  clearAllFilters() {
    this.customMultipleFilters = []
    this.selectedStatus = null
    this.applyFilters()
  }

  changeCustomFilters(event: Event, value: any, isSM = false) {
    if ((event.target as HTMLInputElement).checked) {
      if (isSM) {
        this.customMultipleFilters.push({ name: value.name, value: value.affId, type: 'SM' })
      } else {
        this.customMultipleFilters.push({ name: value.name, value: value.companyId, type: 'COMPANY' })
      }
    } else {
      if (isSM) {
        this.customMultipleFilters = this.customMultipleFilters.filter((f) => f.value != value.affId)
      } else {
        this.customMultipleFilters = this.customMultipleFilters.filter((f) => f.value != value.companyId)
      }
    }
    this.applyFilters()
  }

  applyCustomFilters() {
    if (this.customMultipleFilters.length > 0) {
      this.tasks = this.tasks.filter((task) => {
        for (let f of this.customMultipleFilters) {
          if (f.type == 'SM') {
            if (task.requesterAffId == f.value) {
              return true
            }
          } else {
            if (task.requesterCompanyId == f.value) {
              return true
            }
          }
        }
        return false
      })
    }
    this.applySearchFilter()
  }

  isCustomFilterSelected(value: any) {
    return this.customMultipleFilters.find((f) => f.value == value)
  }

  applySearchFilter(keyword?: string) {
    if (keyword || keyword == '') {
      this.searchKeyword = keyword
    }
    this.filteredTasks = this.tasks.filter((task) => {
      if (task.title.match(new RegExp(this.searchKeyword, 'i'))) {
        return true
      }
    })
    if (this.sortedBy) {
      this.sort()
    }
  }

  async filterCompanies(keyword: string = '') {
    let relatedCompanyIds = new Map()
    let tasks = await this.stateService.getTasks()
    tasks.forEach((task) => relatedCompanyIds.set(task.requesterCompanyId, task.requesterCompanyName))
    this.filteredCompanies = []
    relatedCompanyIds.forEach((name, companyId) =>
      name.match(new RegExp(keyword, 'i')) ? this.filteredCompanies.push({ name, companyId }) : null
    )
  }

  async filterSMs(keyword: string = '') {
    let relatedContactAffIds = new Map()
    let tasks = await this.stateService.getTasks()
    tasks.forEach((task) => relatedContactAffIds.set(task.requesterAffId, task.getRequesterName()))
    this.filteredSMs = []
    relatedContactAffIds.forEach((name, affId) =>
      name.match(new RegExp(keyword, 'i')) ? this.filteredSMs.push({ name, affId }) : null
    )
  }

  deselectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className)
    Array.from(allCheckboxes).forEach((el: HTMLInputElement) => (el.checked = false))
    if (className == 'companyCheckbox') {
      this.filteredCompanies.forEach((company) => {
        this.customMultipleFilters = this.customMultipleFilters.filter((f) => f.value != company.companyId)
      })
    } else if (className == 'contactCheckbox') {
      this.filteredSMs.forEach((contact) => {
        this.customMultipleFilters = this.customMultipleFilters.filter((f) => f.value != contact.affId)
      })
    }
    this.applyFilters()
  }

  selectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className)
    Array.from(allCheckboxes).forEach((el: HTMLInputElement) => (el.checked = true))
    if (className == 'companyCheckbox') {
      this.filteredCompanies.forEach((company) => {
        if (!this.customMultipleFilters.find((f) => f.value == company.companyId)) {
          this.customMultipleFilters.push({ name: company.name, value: company.companyId, type: 'COMPANY' })
        }
      })
    } else if (className == 'contactCheckbox') {
      this.filteredSMs.forEach((contact) => {
        if (!this.customMultipleFilters.find((f) => f.value == contact.affId)) {
          this.customMultipleFilters.push({ name: contact.name, value: contact.affId, type: 'SM' })
        }
      })
    }
    this.applyFilters()
  }

  getCustomFilterText() {
    let text = ''
    if (this.selectedStatus == REQUEST_TASK_STATUS.CREATED) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.sent_requests')}`
    } else if (this.selectedStatus == REQUEST_TASK_STATUS.IN_PROGRESS) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.progress_requests')}`
    } else if (this.selectedStatus == REQUEST_TASK_STATUS.APPROVED) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.completed_requests')}`
    }
    if (this.selectedStatus && this.customMultipleFilters.length > 0) {
      text += ` ${this.locale('locale_key.pages.datahub.data_entries.and')} `
    }
    text += this.customMultipleFilters.map((f) => f.name).join(', ')
    return text
  }

  sort(option?) {
    if (option) {
      this.sortedBy = option
    }
    let sortFun
    if (this.sortedBy.value == 'createdDate') {
      sortFun = (a: TaskFe, b: TaskFe) => {
        return DateUtil.getTimeDifference(a.date, b.date)
      }
    } else if (this.sortedBy.value == 'createdDateDes') {
      sortFun = (a: TaskFe, b: TaskFe) => {
        return DateUtil.getTimeDifference(b.date, a.date)
      }
    } else if (this.sortedBy.value == 'deadline') {
      sortFun = (a: TaskFe, b: TaskFe) => {
        return DateUtil.getTimeDifference(a.dueDate, b.dueDate)
      }
    } else if (this.sortedBy.value == 'deadlineDes') {
      sortFun = (a: TaskFe, b: TaskFe) => {
        return DateUtil.getTimeDifference(b.dueDate, a.dueDate)
      }
    } else if (this.sortedBy.value == 'name') {
      sortFun = (a: TaskFe, b: TaskFe) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1
        } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1
        } else {
          return 0
        }
      }
    } else if (this.sortedBy.value == 'nameDes') {
      sortFun = (a: TaskFe, b: TaskFe) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return 1
        } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return -1
        } else {
          return 0
        }
      }
    }
    if (sortFun) {
      this.filteredTasks = this.filteredTasks.sort(sortFun)
    }
  }
}

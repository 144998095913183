<div
  class="mx-0 px-0 card h-100 w-100 d-flex flex-column"
  *ngIf="!inProgress"
  style="max-width: 100% !important; box-sizing: border-box !important"
>
  <!-- Questionnaire title bar-->
  <div
    class="d-flex flex-row justify-content-between flex-wrap w-100 p-1 gray-bg"
    style="box-sizing: border-box; height: var(--pages-header-footer-height)"
  >
    <div style="max-width: 100%; box-sizing: border-box">
      <span class="fs-5 mb-0 pb-0">{{ locale('locale_key.pages.task_portal.questionnaire_preview') }}</span>
    </div>
    <div class="d-flex flex-row my-auto">
      <div
        class="d-flex flex-row pb-0 justify-content-end my-auto pt-1"
        *ngIf="viewNo == 2 && (!screenSize.isSmallerSize() || !screenSize.isXSmallSize())"
      >
        <label class="d-inline me-1">{{ locale('locale_key.pages.task_portal.answer_wizard.progress') }}</label>
        <progress class="progress-bar mt-1" value="{{ progress }}" max="100"></progress>
      </div>
      <i
        *ngIf="showQuestionnaireIcon && viewNo === 1"
        class="la la-arrows-alt fs-4 ms-auto mx-2 my-auto"
        (click)="onIconClick()"
      ></i>
    </div>
  </div>
  <!-- Questionnaire detail bar-->
  <div
    class="d-flex flex-lg-row align-items-lg-center justify-content-center align-items-start flex-md-column flex-column p1 thin-border-b"
    style="box-sizing: border-box; height: var(--pages-header-footer-height)"
  >
    <div class="col-lg-3 col-md-12 px-2 my-auto">
      <span class="me-2 dark-text text-wrap">{{ task.getRequesterName() }}</span>
      <span class="text-muted">({{ task.requesterCompanyName }})</span>
    </div>
    <div class="col-lg-4 col-md-12 px-2 my-auto" [ngClass]="{ 'border-l border-r': screenSize.isXLargeSize() }">
      <p class="dark-text mx-auto pb-0 text-wrap mb-0 pb-0" *ngIf="task.requestGroup.isChildRecurring">
        {{ task.getTitle() }}
      </p>
      <span class="me-2 dark-text mx-auto mt-1 pt-0">
        {{ locale('locale_key.pages.task_portal.deadline') }} {{ task.dueDate | date: 'dd MMM, yyyy' }}
      </span>
    </div>
    <div
      class="col-lg-5 col-md-12 d-flex flex-row justify-content-between px-2 my-auto"
      [ngClass]="screenSize.isXLargeSize() || screenSize.isLargeSize() ? '' : 'w-100 d-none'"
    >
      <a
        class="mx-auto m-1 p-1"
        [ngClass]="
          screenSize.isXLargeSize() || screenSize.isLargeSize()
            ? 'btn tertiary-button open-message-box-btn'
            : 'underline'
        "
        (click)="openMessageModal()"
      >
        <i
          class="la la-comment-alt fs-4"
          style="position: relative; top: 3px"
          *ngIf="screenSize.isXLargeSize() || screenSize.isLargeSize()"
        ></i>
        <small>{{ locale('locale_key.general.button.open_conversation') }}</small>
      </a>
      <a
        class="mx-auto mx-2 m-1 p-1"
        [ngClass]="
          screenSize.isXLargeSize() || screenSize.isLargeSize()
            ? 'btn tertiary-button open-message-box-btn'
            : 'underline'
        "
        (click)="startReview()"
        *ngIf="isReviewedOnce && viewNo != 3"
      >
        <i
          class="las la-list-alt fs-4"
          style="position: relative; top: 3px"
          *ngIf="screenSize.isXLargeSize() || screenSize.isLargeSize()"
        ></i>
        <small>{{ locale('locale_key.pages.task_portal.button.review_answers') }}</small>
      </a>
      <a
        class="mx-auto m-1 p-1"
        [ngClass]="
          screenSize.isXLargeSize() || screenSize.isLargeSize()
            ? 'btn tertiary-button open-message-box-btn'
            : 'underline'
        "
        (click)="openModal(exitQuestionnaireModal, 'modal-md')"
        *ngIf="viewNo != 1 && isFromInboxPage"
      >
        <i
          class="las la-inbox fs-4"
          style="position: relative; top: 3px"
          *ngIf="screenSize.isXLargeSize() || screenSize.isLargeSize()"
        ></i>
        <small>{{ locale('locale_key.pages.task_portal.back_to_inbox') }}</small>
      </a>
    </div>
  </div>
  <!-- Questionnaire preview: view 1-->
  <div class="mx-3 my-2" *ngIf="viewNo == 1" style="height: var(--page-content-minus-4-page-header-footer)">
    <!--New questionnaire preview design starts-->
    <div class="d-flex flex-row py-3 px-2 my-auto" *ngIf="task.description" style="position: relative">
      <a
        class="dropdown-toggle m-0 p-0 text-wrap"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style="max-width: 100%; box-sizing: border-box"
      >
        {{ slocale('Request description') }}
      </a>
      <div
        class="dropdown-menu thin-border task-description-item resizable-dropdown"
        style="position: absolute; top: 100%; left: 0; width: auto"
      >
        <p class="px-2 collapse multiline show" id="desc">{{ task.description }}</p>
      </div>
    </div>
    <!--New questionnaire preview design ends-->
    <ng-container *ngTemplateOutlet="previewQuestionnaireBody"></ng-container>
  </div>
  <!--Ansering questions page: view 2-->

  <div
    class="d-flex flex-lg-row flex-column mb-0 z-1 overflow-hidden scroll-y max-100vw"
    *ngIf="viewNo == 2"
    [ngClass]="{ 'pb-0': screenSize.isLargeSize() || screenSize.isXLargeSize() }"
    style="height: var(--page-content-minus-4-page-header-footer)"
  >
    <!--Previsously answered questions: the left progress page-->
    <div class="col-lg-3 col-md-12 col-sm-12 thin-border-r scroll-y" (scroll)="onScroll()">
      <!--New questionnaire design starts-->
      <div class="d-flex flex-column p-2 bg-light border-b h-lg-75 h-auto box-border">
        <div>
          <a
            [ngClass]="{ disabled: !isShowAllQuestionsButtonActive() }"
            class="mx-auto m-1 p-1 btn tertiary-button open-message-box-btn"
            (click)="openModal(previewQuestionnaireModal, 'modal-lg')"
          >
            <i class="la la-file-alt fs-4" style="position: relative; top: 3px"></i>
            <small>{{ slocale('Show all questions') }}</small>
          </a>
        </div>
        <div class="d-flex">
          <span class="text-muted fs-6">
            {{ slocale('Answered and in progress questions:') }}
          </span>
          <div class="ms-2 pt-1">
            <i
              data-bs-toggle="tooltip"
              [attr.title]="
                slocale(
                  'Here you can find the questions you have answered, explored, or are currently working on. Follow-up questions will only reflect the most recent path you have picked.'
                )
              "
              class="la la-exclamation-circle fs-4"
            ></i>
          </div>
        </div>

        <span class="text-muted fs-6 mt-3" *ngIf="!hasAnyAnswers()">
          {{ locale('locale_key.pages.task_portal.title.not_answered_yet') }}
        </span>
        <div class="scroll-y">
          <ng-container *ngFor="let section of task.questionnaire.sections; let j = index">
            <ng-container *ngFor="let question of section.questions; let i = index">
              <div
                #sidePanelItem
                class="mt-2 bg-white d-flex flex-column"
                [ngClass]="{
                  'bg-white': activeQuestion.id !== question.id,
                  'side-panel-selected-question': activeQuestion.id === question.id
                }"
                *ngIf="!question.isFollowUpQues && shouldDisplayQuestionInSidePanel(question)"
              >
                <a
                  (click)="navigateOnSidePanel(question)"
                  class="truncate-two-lines"
                  [ngClass]="{ nonRelevantQuestion: !question.isRelevant }"
                >
                  <span class="me-2">{{ questionsRefNumber.get(question.id) }}</span>
                  {{ question.question }}
                </a>

                <!-- Show/Hide follow up questions button -->
                <a
                  class="underline mt-2 bold"
                  (click)="showFollowUpQuestionsAnswerView(question.id)"
                  *ngIf="shouldDisplayShowFollowUpQuestionsInSidePanel(section, question)"
                >
                  {{ slocale('Show follow up questions') }}
                </a>
                <a
                  class="underline mt-2 bold"
                  (click)="hideFollowUpQuestionsAnswerView(question.id)"
                  *ngIf="shouldShowHideFollowUpQuestionsInSidePanel(section, question)"
                >
                  {{ slocale('Hide follow up questions') }}
                </a>

                <!-- Follow-up questions -->
                <div class="bg-white p-2" *ngIf="followupsExpandedInAnswerView[question.id]">
                  <ng-container *ngFor="let childQuestion of getAllChildrenForSidePanel(section, question)">
                    <div
                      #sidePanelItem
                      [ngClass]="{
                        'bg-white': activeQuestion.id !== childQuestion.id,
                        'side-panel-selected-question': activeQuestion.id === childQuestion.id
                      }"
                    >
                      <a (click)="navigateOnSidePanel(childQuestion)" class="truncate-two-lines">
                        <span class="me-2">{{ questionsRefNumber.get(childQuestion.id) }}</span>
                        {{ childQuestion.question }}
                      </a>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <!--New questionnaire design ends-->
    </div>
    <!--Current question form to answer-->
    <div
      class="col-lg-9 col-md-12 col-sm-12 d-flex flex-column scroll-y"
      style="background-color: var(--main-light-color)"
      [ngClass]="{
        'my-auto h-100': screenSize.isLargeSize() || screenSize.isXLargeSize(),
        'h-auto': screenSize.isSmallerSize() || screenSize.isMediumSize()
      }"
    >
      <div
        class="d-flex flex-column justify-content-center align-items-center py-3 h-100 my-auto"
        *ngIf="activeQuestion"
        [ngStyle]="{ 'max-width': screenSize.isLargeSize() || screenSize.isXLargeSize() ? '65vw' : '100vh' }"
      >
        <div class="scroll-y d-flex flex-column w-100 h-100 px-3">
          <!--New questionnaire design starts-->
          <div class="my-1" *ngIf="activeQuestion.parentQuestionId">
            <div class="d-flex flex-lg-row flex-column justify-content-between my-auto">
              <div
                class="d-flex flex-lg-row flex-column justify-content-between bg-light p-3 col-lg-12 col-md-12 col-sm-12 mb-2"
              >
                <div class="d-flex flex-column my-auto">
                  <h5 class="dark-text pb-0 mb-0 my-auto">
                    {{ slocale('This follow up question is based on your answer to ') }}
                  </h5>
                  <h5 class="dark-text pb-0 mb-0 my-auto bold my-2">
                    <span class="bold">
                      {{ questionsRefNumber.get(activeQuestion.parentQuestionId) }}
                      {{ getParentQuestion(activeQuestion)?.question }}
                    </span>
                  </h5>
                  <ng-container>
                    <div class="d-flex flex-row my-auto">
                      <span class="text-info my-auto bold my-2">{{ slocale('Answer') }}:&nbsp;&nbsp;&nbsp;</span>
                      <input
                        class="mt-2 bg-pink form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked
                      />
                      <span class="my-auto bold my-2">
                        &nbsp;&nbsp; {{ task.questionnaire.ifAnswerIsStringForFollowup(activeQuestion) }}
                      </span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <div
            [ngClass]="{
              'ps-4': activeQuestion.isFollowUpQues
            }"
          >
            <!--New questionnaire design ends-->
            <h5
              [ngClass]="{
                'accordion-toggle': activeQuestion.desc && activeQuestion.isRelevant,
                nonRelevantQuestion: !activeQuestion.isRelevant
              }"
              id="question-heading"
              data-bs-toggle="collapse"
              data-bs-target="#ques-collapseOne"
              aria-expanded="true"
              aria-controls="ques-collapseOne"
            >
              <ng-container
                *ngTemplateOutlet="
                  nonRelevantQuestionIcon;
                  context: { isRelevant: activeQuestion.isRelevant, disableTooltip: true }
                "
              ></ng-container>
              {{ questionsRefNumber.get(activeQuestion.id) }} {{ activeQuestion.question }}
              <sup class="text-danger pink">{{ activeQuestion.required ? '*' : '' }}</sup>
            </h5>

            <div
              *ngIf="shouldShowCsrdLegislationLabel(activeQuestion)"
              class="m-1 d-flex flex-row justify-content-between"
            >
              <ng-container *ngTemplateOutlet="csrdInfoBadge; context: { question: activeQuestion }"></ng-container>
            </div>
            <div
              class="d-flex flex-column flex-lg-row justify-content-between p-3 bg-comp-grey mt-1"
              *ngIf="!activeQuestion.isRelevant"
            >
              <span class="italic text-dark">
                {{ slocale('This question is not relevant for you, no response is needed.') }}
              </span>
              <a class="underline" (click)="toggleShouldShowNonRelevantQuestDetails()">
                {{ !shouldShowDetailsForCurrentNonRelQues ? slocale('Show ') : slocale('Hide ') }}
                {{ slocale('question details') }}
              </a>
            </div>
            <div
              class="nonRelevantQuestion disabled"
              *ngIf="activeQuestion.isRelevant || shouldShowDetailsForCurrentNonRelQues"
            >
              <div
                id="ques-collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="question-heading"
                *ngIf="activeQuestion.desc && activeQuestion.isRelevant"
              >
                <i class="text-muted multiline ps-3">{{ activeQuestion.desc }}</i>
              </div>

              <div class="ps-3 mt-3">
                <span
                  *ngIf="activeQuestion.desc && !activeQuestion.isRelevant"
                  class="italic text-dark accordion-toggle collapsed"
                  id="description-heading"
                  data-bs-toggle="collapse"
                  data-bs-target="#ques-description"
                  aria-controls="ques-description"
                >
                  {{ slocale('Description') }}
                </span>

                <div
                  id="ques-description"
                  class="accordion-collapse collapse"
                  aria-labelledby="description-heading"
                  *ngIf="activeQuestion.desc && !activeQuestion.isRelevant"
                >
                  <i class="text-muted multiline">{{ activeQuestion.desc }}</i>
                </div>
              </div>

              <div class="col mt-1 mx-0 px-0 py-2" *ngIf="requestService.isTextQues(activeQuestion)">
                <textarea
                  (input)="onAnswerChanged()"
                  [disabled]="!activeQuestion.isRelevant"
                  class="form-control ms-3"
                  [ngClass]="screenSize.isXLargeSize() || screenSize.isLargeSize() ? 'w-75' : 'w-100'"
                  rows="5"
                  placeholder="Write answer"
                  [(ngModel)]="activeQuestion.newAnswer['text']"
                  (keyup)="autoGrowTextZone($event)"
                  [ngClass]="{
                    'is-invalid':
                      activeQuestion.newAnswer.isSubmitted &&
                      activeQuestion.required &&
                      activeQuestion.newAnswer.isInvalid(true)
                  }"
                ></textarea>
                <div
                  *ngIf="
                    activeQuestion.newAnswer.isSubmitted &&
                    activeQuestion.newAnswer.isInvalid(true) &&
                    activeQuestion.required
                  "
                  class="invalid-feedback"
                >
                  {{ requiredQuestionMessage }}
                </div>
              </div>
              <div class="row my-2 ps-3 py-2" *ngIf="requestService.isEmissionFactorQues(activeQuestion)">
                <div class="w-auto p-0">
                  <div *ngIf="!containsPredefinedOptions()">
                    <emission-factor
                      [selectedEF]="activeQuestion.newAnswer['selectedEmissionFactor']"
                      isEditable="activeQuestion.isRelevant"
                      [mgmtCompanyId]="task.managementCompanyId"
                      [datapointEFUnit]="
                        activeQuestion.mappedToColKeys && activeQuestion.mappedToColKeys.length > 0
                          ? {
                              sourceUnit: activeQuestion['datapointEf']?.sourceUnit,
                              conversionUnit: activeQuestion['datapointEf']?.conversionUnit
                            }
                          : null
                      "
                      (emissionFactorSelected)="setEmissionFactorAnswerFromEF($event)"
                      [displayStyle]="'SOURCE_UNIT'"
                    ></emission-factor>
                    <div
                      *ngIf="
                        activeQuestion.newAnswer.isSubmitted &&
                        activeQuestion.newAnswer.isInvalid(true) &&
                        activeQuestion.required
                      "
                      class="invalid-feedback"
                    >
                      {{ requiredQuestionMessage }}
                    </div>
                  </div>
                  <div *ngIf="containsPredefinedOptions()">
                    <ng-container class="form-group position-relative ms-0 m-0 p-0">
                      <div class="dropdown">
                        <div
                          id="dropdownMenuButton-ef"
                          data-bs-auto-close="outside"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-display="static"
                          class="w-auto py-2"
                        >
                          <div class="select_dropdown-menu">
                            <ng-container *ngIf="getSelectedEmissionFactor(); else defaultOption">
                              <ng-container>
                                <div>
                                  <i class="la la-fire-alt"></i>
                                  <span>
                                    {{
                                      locale('locale_key.pages.data_request.emissionfactor_display_value', {
                                        conversionFactor: getSelectedEmissionFactor().conversionFactor,
                                        conversionUnit: getSelectedEmissionFactor().conversionUnit,
                                        sourceName: getSelectedEmissionFactor().sourceName
                                      })
                                    }}
                                  </span>
                                  <i
                                    class="la la-info-circle float-end info-popover-arrow ms-2 pt-2"
                                    type="button"
                                    (click)="openEfDetailTab()"
                                  ></i>
                                </div>
                              </ng-container>
                            </ng-container>
                            <ng-template #defaultOption>
                              {{ locale('locale_key.pages.data_request.create.follow_up_condition.placeholder') }}
                            </ng-template>
                            <span class="caret">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="12"
                                viewBox="0 0 11 12"
                                fill="none"
                              >
                                <path
                                  d="M1.375 3.9375L5.5 8.0625L9.625 3.9375"
                                  stroke="#6B6E80"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                        <ul class="dropdown-menu sticky-top predefinedDropdown" aria-labelledby="dropdownMenuButton-ef">
                          <li
                            *ngFor="let option of activeQuestion['predefinedOptions']; let i = index"
                            [ngClass]="{
                              'border-bottom': activeQuestion['customValuesAllowedWhenPredefinedOptionsSet']
                                ? i < activeQuestion['predefinedOptions'].length
                                : i < activeQuestion['predefinedOptions'].length - 1
                            }"
                          >
                            <div class="dropdown-item user-select-none text-wrap">
                              <span
                                (click)="setEmissionFactorAnswer(option)"
                                class="user-select-none"
                                [ngClass]="{ 'fst-italic': recentlyAddedCustomOptions.includes(option) }"
                              >
                                <i class="la la-fire-alt"></i>
                                <span>
                                  {{
                                    locale('locale_key.pages.data_request.emissionfactor_display_value', {
                                      conversionFactor: option.selectedEmissionFactor?.conversionFactor,
                                      conversionUnit: option.selectedEmissionFactor?.conversionUnit,
                                      sourceName: option.selectedEmissionFactor?.sourceName
                                    })
                                  }}
                                </span>
                              </span>
                              <span class="float-end ms-2">
                                <i
                                  class="la la-info-circle info-popover-arrow"
                                  type="button"
                                  (click)="openEfDetailTab(option.selectedEmissionFactor)"
                                ></i>
                              </span>
                            </div>
                          </li>
                          <li *ngIf="activeQuestion['customValuesAllowedWhenPredefinedOptionsSet']">
                            <emission-factor
                              isEditable="activeQuestion.isRelevant"
                              [mgmtCompanyId]="task.managementCompanyId"
                              [datapointEFUnit]="
                                activeQuestion.mappedToColKeys && activeQuestion.mappedToColKeys.length > 0
                                  ? {
                                      sourceUnit: activeQuestion['datapointEf']?.sourceUnit,
                                      conversionUnit: activeQuestion['datapointEf']?.conversionUnit
                                    }
                                  : null
                              "
                              (emissionFactorSelected)="setEmissionFactorAnswerOptionFromEF($event)"
                              [wipeSelectedEF]="true"
                              [customButtonHtml]="true"
                              [displayStyle]="'SOURCE_UNIT'"
                            ></emission-factor>
                          </li>
                        </ul>
                      </div>
                    </ng-container>
                  </div>
                  <div
                    *ngIf="
                      activeQuestion.newAnswer.isSubmitted &&
                      activeQuestion.newAnswer.isInvalid(true) &&
                      activeQuestion.required
                    "
                    class="invalid-feedback d-inline"
                  >
                    {{ requiredQuestionMessage }}
                  </div>
                </div>
              </div>
              <div class="row my-2 ps-3 py-2" *ngIf="requestService.isDateQues(activeQuestion)">
                <div class="w-auto p-0">
                  <div *ngIf="!containsPredefinedOptions()" class="d-flex flex-row align-items-center">
                    <div class="flex-column me-3">
                      <input
                        (input)="onAnswerChanged()"
                        [disabled]="!activeQuestion.isRelevant"
                        type="date"
                        class="form-control input-type-button"
                        [placeholder]="datePlaceholder"
                        [(ngModel)]="activeQuestion.newAnswer['dateFromInput']"
                        (ngModelChange)="setDateFrom($event)"
                        [ngClass]="{
                          'is-invalid':
                            (activeQuestion.newAnswer.isSubmitted &&
                              activeQuestion.required &&
                              !activeQuestion.newAnswer['dateFrom'] &&
                              activeQuestion.newAnswer['dateFromInput'] == '') ||
                            isInvalidDateFrom(activeQuestion.newAnswer['dateFrom'])
                        }"
                        [title]="getInvalidYearTooltipFrom(activeQuestion.newAnswer['dateFrom'])"
                      />
                    </div>
                    <div *ngIf="activeQuestion['range']" class="flex-column me-3 justify-content-center">
                      <h5 class="mt-1 mx-lg-auto me-md-auto me-sm-auto">
                        {{ locale('locale_key.pages.task_portal.answer_wizard.date.options.range.to') }}
                      </h5>
                    </div>
                    <div *ngIf="activeQuestion['range']" class="flex-column">
                      <input
                        [disabled]="!activeQuestion.isRelevant"
                        type="date"
                        class="form-control input-type-button"
                        [placeholder]="datePlaceholder"
                        [attr.min]="getMinDate(activeQuestion.newAnswer['dateFrom'])"
                        [(ngModel)]="activeQuestion.newAnswer['dateToInput']"
                        (ngModelChange)="setDateTo($event)"
                        [ngClass]="{
                          'is-invalid':
                            (activeQuestion.newAnswer.isSubmitted &&
                              activeQuestion.required &&
                              !activeQuestion.newAnswer['dateTo'] &&
                              activeQuestion.newAnswer['dateToInput'] == '') ||
                            isInvalidDateTo(activeQuestion.newAnswer['dateFrom'], activeQuestion.newAnswer['dateTo'])
                        }"
                        [title]="
                          getInvalidYearTooltipRange(
                            activeQuestion.newAnswer['dateFrom'],
                            activeQuestion.newAnswer['dateTo']
                          )
                        "
                      />
                    </div>
                  </div>

                  <div *ngIf="containsPredefinedOptions()" class="form-group position-relative ms-0 m-0 p-0">
                    <div class="dropdown">
                      <div
                        id="dropdownMenuButton"
                        data-bs-auto-close="outside"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-display="static"
                        class="w-75"
                      >
                        <div class="select_dropdown-menu">
                          <ng-container>
                            <span class="text-truncate">{{ getSelectedAnswerOfDateQuestion() }}</span>
                          </ng-container>
                          <span class="caret">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="12"
                              viewBox="0 0 11 12"
                              fill="none"
                            >
                              <path
                                d="M1.375 3.9375L5.5 8.0625L9.625 3.9375"
                                stroke="#6B6E80"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <ul class="dropdown-menu sticky-top predefinedDropdown" aria-labelledby="dropdownMenuButton">
                        <li
                          *ngFor="let option of getSortedPredfinedDateOptions(activeQuestion); let i = index"
                          [ngClass]="{
                            'border-bottom': activeQuestion['customValuesAllowedWhenPredefinedOptionsSet']
                              ? i < activeQuestion['predefinedOptions'].length
                              : i < activeQuestion['predefinedOptions'].length - 1
                          }"
                        >
                          <span
                            *ngIf="!activeQuestion['range']"
                            class="dropdown-item user-select-none text-wrap"
                            (click)="setDateAnswer(option, activeQuestion['range'])"
                            [ngClass]="{ 'fst-italic': recentlyAddedCustomOptions.includes(option) }"
                          >
                            {{ option.from | date: 'dd MMM, yyyy' }}
                          </span>
                          <span
                            *ngIf="activeQuestion['range']"
                            class="dropdown-item user-select-none text-wrap"
                            (click)="setDateAnswer(option, activeQuestion['range'])"
                            [ngClass]="{ 'fst-italic': recentlyAddedCustomOptions.includes(option) }"
                          >
                            {{ option.from | date: 'dd MMM, yyyy' }}
                            {{ locale('locale_key.pages.data_request.create_question.date.options.range.to') }}
                            {{ option.to | date: 'dd MMM, yyyy' }}
                          </span>
                        </li>
                        <li *ngIf="activeQuestion['customValuesAllowedWhenPredefinedOptionsSet']">
                          <div
                            *ngIf="!activeQuestion.isCustomOptionAdded"
                            class="dropdown-item user-select-none text-wrap"
                            (click)="activeQuestion.isCustomOptionAdded = true"
                          >
                            <i class="icon las la-plus p-2 size-15 bold"></i>
                            <span class="add_label">{{ locale('locale_key.general.add_custom_option') }}</span>
                          </div>
                          <div
                            *ngIf="activeQuestion.isCustomOptionAdded"
                            class="dropdown-item d-flex flex-column flex-lg-row align-items-center w-auto"
                          >
                            <input
                              [disabled]="!activeQuestion.isRelevant"
                              type="date"
                              #customPredefineAnswerDateFrom
                              class="flex-grow-1 flex-shrink-1 form-control input-m my-1 input-type-button"
                              placeholder="dd MMM, yyyy"
                            />

                            <span *ngIf="activeQuestion['range']" class="px-2 my-xl-1">
                              {{ locale('locale_key.general.data_type.date_range.to') }}
                            </span>

                            <input
                              [disabled]="!activeQuestion.isRelevant"
                              type="date"
                              #customPredefineAnswerDateTo
                              class="flex-grow-1 flex-shrink-1 form-control input-m my-1 input-type-button"
                              [ngClass]="{
                                hidden: !activeQuestion['range']
                              }"
                              placeholder="dd MMM, yyyy"
                              [attr.min]="getMinDate(customPredefineAnswerDateFrom)"
                            />
                            <div
                              class="user-select-none d-flex align-items-center ms-auto px-2 my-1"
                              (click)="
                                addCustomPredefinedDateOption(
                                  activeQuestion,
                                  activeQuestion['range'],
                                  customPredefineAnswerDateFrom,
                                  activeQuestion['range'] ? customPredefineAnswerDateTo : undefined
                                )
                              "
                            >
                              <i class="icon las la-plus fs-5" style="position: relative; top: 1px"></i>
                              <span class="add_label ms-2">{{ locale('locale_key.general.buttons.add') }}</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      activeQuestion.newAnswer.isSubmitted &&
                      activeQuestion.newAnswer.isInvalid(true) &&
                      activeQuestion.required
                    "
                    class="invalid-feedback d-inline"
                  >
                    {{ requiredQuestionMessage }}
                  </div>
                </div>
              </div>
              <div *ngIf="requestService.isNumberQues(activeQuestion)">
                <div class="p-0 w-auto">
                  <div class="row ps-3 py-2">
                    <div *ngIf="!containsPredefinedOptions() || isUnitOnly()" class="col">
                      <div>
                        <input
                          (input)="onAnswerChanged()"
                          [disabled]="!activeQuestion.isRelevant"
                          type="number"
                          min="0"
                          (keydown)="filterNumberInput($event)"
                          class="form-control rounded-0 h-38"
                          placeholder="{{ locale('locale_key.pages.data_request.create.question.value.placeholder') }}"
                          [ngModel]="activeQuestion.newAnswer['value']"
                          (ngModelChange)="updateNumberAnswer($event)"
                          [ngClass]="{
                            'is-invalid':
                              activeQuestion.newAnswer.isSubmitted &&
                              activeQuestion.required &&
                              activeQuestion.newAnswer.isInvalid(true)
                          }"
                        />
                        <div *ngIf="inputNumberHasError" class="highlight-validation-message">
                          {{ locale('locale_key.pages.task_portal.answer_wizard.button.number.message') }}
                        </div>
                      </div>
                      <div
                        *ngIf="
                          activeQuestion.newAnswer.isSubmitted &&
                          activeQuestion.newAnswer.isInvalid(true) &&
                          activeQuestion.required
                        "
                        class="invalid-feedback"
                      >
                        {{ requiredQuestionMessage }}
                      </div>
                    </div>
                    <div *ngIf="!containsPredefinedOptions()" class="col">
                      <unit-selector
                        [mode]="'select'"
                        (click)="alignDropdown($event)"
                        [selectBy]="'symbol'"
                        (selectedUnitChanged)="setUnitForAnswer($event)"
                        [source]="'add_datapoint_modal answerComponent'"
                        [selectedUnitSymbol]="activeQuestion.newAnswer['unit']"
                        [includeMeasurementTypes]="getMeasurementType()"
                        [customUnits]="getCustomUnits()"
                        class="mt-1 h-38"
                        [skipInitNoUnit]="skipInitNoUnit()"
                      ></unit-selector>
                      <ng-container *ngIf="!activeQuestion['askUnitFromDO']">
                        {{ activeQuestion.newAnswer['unit'] }}
                      </ng-container>
                    </div>
                    <div *ngIf="containsPredefinedOptions()" class="col">
                      <div *ngIf="isUnitOnly()">
                        <input
                          [disabled]="!activeQuestion.isRelevant"
                          class="form-control rounded-0 h-38"
                          type="text"
                          placeholder="{{ getUnitString(activeQuestion['predefinedOptions'][0].unit) }}"
                          disabled
                        />
                      </div>
                      <div *ngIf="!isUnitOnly()" class="dropdown">
                        <div
                          id="dropdownMenuButton"
                          data-bs-auto-close="outside"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-display="static"
                          [ngClass]="isUnitOnly() ? 'w-100' : 'w-75'"
                        >
                          <div class="select_dropdown-menu">
                            <ng-container>
                              <span class="text-truncate">{{ getSelectedAnswerOfNumberQuestion() }}</span>
                            </ng-container>
                            <span class="caret">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="12"
                                viewBox="0 0 11 12"
                                fill="none"
                              >
                                <path
                                  d="M1.375 3.9375L5.5 8.0625L9.625 3.9375"
                                  stroke="#6B6E80"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                        <ul
                          class="dropdown-menu sticky-top predefinedDropdown wider-dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <li
                            *ngFor="let option of getSortedPredfinedNumberOptions(activeQuestion); let i = index"
                            [ngClass]="{
                              'border-bottom': activeQuestion['customValuesAllowedWhenPredefinedOptionsSet']
                                ? i < activeQuestion['predefinedOptions'].length
                                : i < activeQuestion['predefinedOptions'].length - 1
                            }"
                          >
                            <span
                              *ngIf="option.value == null"
                              class="dropdown-item user-select-none fst-bold"
                              (click)="setNumberAnswer('unit', option)"
                              [ngClass]="{ 'fst-italic': recentlyAddedCustomOptions.includes(option) }"
                            >
                              {{ getUnitString(option.unit) }}
                            </span>
                            <span
                              *ngIf="option.value != null"
                              class="dropdown-item user-select-none text-wrap"
                              (click)="setNumberAnswer('unit-value', option)"
                              [ngClass]="{ 'fst-italic': recentlyAddedCustomOptions.includes(option) }"
                            >
                              {{ option.value }}
                              <strong>{{ getUnitString(option.unit) }}</strong>
                            </span>
                          </li>
                          <li *ngIf="isValueAndUnit() && activeQuestion['customValuesAllowedWhenPredefinedOptionsSet']">
                            <div class="row m-1">
                              <div
                                *ngIf="!activeQuestion.isCustomOptionAdded"
                                class="col-12 user-select-none m-2"
                                (click)="activeQuestion.isCustomOptionAdded = true"
                              >
                                <i class="icon las la-plus fs-5" style="position: relative; top: 1px"></i>
                                <span class="add_label px-1">{{ locale('locale_key.general.add_custom_option') }}</span>
                              </div>
                              <div
                                *ngIf="activeQuestion.isCustomOptionAdded"
                                class="col dropdown-item user-select-none text-wrap"
                              >
                                <div class="container">
                                  <div
                                    class="d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-sm-start"
                                  >
                                    <div class="flex-item mb-2 mb-md-0 flex-grow-1 me-2">
                                      <input
                                        [disabled]="!activeQuestion.isRelevant"
                                        type="number"
                                        min="0"
                                        [placeholder]="
                                          locale(
                                            'locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.enter_value.placeholder'
                                          )
                                        "
                                        #customPredefineAnswerNumber
                                        class="form-control input-m rounded-0 border border-1 h-38 my-1 input-type-button"
                                      />
                                    </div>

                                    <div class="flex-item mb-2 mb-md-0 flex-grow-1 me-2">
                                      <unit-selector
                                        [mode]="'select'"
                                        [selectBy]="'symbol'"
                                        (selectedUnitChanged)="setUnitForPredefinedAnswer($event)"
                                        [source]="'add_datapoint_modal'"
                                        [selectedUnitSymbol]="getNumberQuestion().tempPredefinedOption?.unit"
                                        [includeMeasurementTypes]="getMeasurementType()"
                                        [customUnits]="getCustomUnits()"
                                        [unitsByMeasurementType]="getUnitsByMeasurementType()"
                                        [skipInitNoUnit]="true"
                                        class="h-38 w-100 my-1 input-type-button"
                                      ></unit-selector>
                                    </div>

                                    <div
                                      class="flex-item mb-2 mb-md-0 d-flex align-items-center justify-content-center justify-content-md-start flex-shrink-0"
                                      (click)="addCustomPredefinedNumberOption(customPredefineAnswerNumber)"
                                    >
                                      <i
                                        class="icon las la-plus me-2 fs-5 my-1"
                                        style="position: relative; top: 1px"
                                      ></i>
                                      <span class="add_label">{{ locale('locale_key.general.buttons.add') }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      activeQuestion.newAnswer.isSubmitted &&
                      activeQuestion.newAnswer.isInvalid(true) &&
                      activeQuestion.required
                    "
                    class="invalid-feedback d-inline"
                  >
                    {{ requiredQuestionMessage }}
                  </div>
                </div>
              </div>
              <div
                class="row mb-2 ps-3 py-2"
                [ngClass]="screenSize.isXLargeSize() || screenSize.isLargeSize() ? 'w-75' : 'w-100'"
                *ngIf="requestService.isAttachmentQues(activeQuestion)"
              >
                <div class="col-12 ms-0 ps-3">
                  <button
                    [disabled]="!activeQuestion.isRelevant"
                    class="btn primary-button custom-button"
                    (click)="isUploading = true; openModal(upload, 'modal-md')"
                    *ngIf="!activeQuestion.newAnswer['attachmentStorageId']"
                  >
                    {{ locale('locale_key.pages.task_portal.answer_wizard.file.button.select_file') }}
                  </button>
                  <div
                    class="card-body mt-0 pt-0 ps-0"
                    *ngIf="activeQuestion.newAnswer['attachmentStorageId'] && !activeQuestion.newAnswer['isLoading']"
                  >
                    <div class="w-100">
                      <div class="d-flex flex-row justify-content-center py-3 open-bx">
                        <div class="col-2 text-start mx-0 px-0">
                          <i class="la la-file-text-o fs-1 me-auto"></i>
                        </div>
                        <div class="col-6 d-flex justify-content-center text-start mx-0 ps-0">
                          <p class="text-muted my-auto me-auto text-truncate">
                            {{ activeQuestion.newAnswer['attachmentLabel'] }}
                          </p>
                        </div>
                        <div class="col-4 d-flex flex-row justify-content-center">
                          <span class="text-muted m-auto underline cursor" (click)="openFile(activeQuestion.newAnswer)">
                            {{ locale('locale_key.general.buttons.open') }}
                          </span>
                          <span class="text-muted m-auto underline cursor" (click)="deleteUploadedAttachment()">
                            {{ locale('locale_key.general.buttons.delete') }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ball-pulse loader-primary" *ngIf="activeQuestion.newAnswer['isLoading']">
                    <div style="background-color: var(--main-dark-color1)"></div>
                    <div style="background-color: var(--main-dark-color1)"></div>
                    <div style="background-color: var(--main-dark-color1)"></div>
                  </div>
                </div>
                <div
                  *ngIf="
                    activeQuestion.newAnswer.isSubmitted &&
                    activeQuestion.newAnswer.isInvalid(true) &&
                    activeQuestion.required
                  "
                  class="invalid-feedback d-inline"
                >
                  {{ requiredQuestionMessage }}
                </div>
              </div>
              <div
                class="my-2 ps-3 py-2 scroll"
                *ngIf="requestService.isDataTableQues(activeQuestion) && !isFileExtractor"
              >
                <div
                  *ngIf="
                    activeQuestion.newAnswer.isSubmitted &&
                    activeQuestion.newAnswer.isInvalid(true) &&
                    activeQuestion.required
                  "
                  class="ps-2 invalid-feedback d-inline"
                >
                  {{
                    !activeQuestion.newAnswer['dataGridService'].isDataValid
                      ? dataTableNotValidMessage
                      : requiredQuestionMessage
                  }}
                </div>
                <div
                  *ngIf="
                    activeQuestion.newAnswer.isSubmitted &&
                    activeQuestion.newAnswer.isInvalid(false) &&
                    !activeQuestion.required
                  "
                  class="ps-2 invalid-feedback d-inline"
                >
                  {{ dataTableNotValidMessage }}
                </div>
                <div class="loader-wrapper m-4" *ngIf="activeQuestion.newAnswer['isLoading']">
                  <div class="ball-grid-pulse loader-primary">
                    <div style="background-color: var(--main-dark-color1)"></div>
                    <div style="background-color: var(--main-dark-color1)"></div>
                    <div style="background-color: var(--main-dark-color1)"></div>
                    <div style="background-color: var(--main-dark-color1)"></div>
                    <div style="background-color: var(--main-dark-color1)"></div>
                    <div style="background-color: var(--main-dark-color1)"></div>
                    <div style="background-color: var(--main-dark-color1)"></div>
                    <div style="background-color: var(--main-dark-color1)"></div>
                    <div style="background-color: var(--main-dark-color1)"></div>
                  </div>
                </div>
                <ng-container *ngIf="!activeQuestion.newAnswer['isLoading']">
                  <div
                    class="ms-0 w-auto scroll"
                    style="width: 100% !important; box-sizing: border-box !important; min-height: 25rem"
                  >
                    <ng-container *ngIf="requestService.isDataTableQues(activeQuestion)">
                      <button
                        class="btn m-2"
                        (click)="setTableView()"
                        [ngClass]="{
                          'bg-pink text-white': !isFormView,
                          'bg-white text-dark tertiary-button': isFormView
                        }"
                      >
                        {{ slocale('Table view') }}
                      </button>
                      <button
                        class="btn m-2"
                        [disabled]="!activeQuestion.isRelevant"
                        (click)="setFormView()"
                        [ngClass]="{
                          'bg-pink text-white': isFormView,
                          'bg-white text-dark tertiary-button': !isFormView
                        }"
                      >
                        {{ slocale('Form View') }}
                      </button>
                    </ng-container>

                    <div [ngClass]="{ 'd-flex flex-row w-100': isFormView }">
                      <div class="w-100 h-100 p-0 m-0" [ngStyle]="getMaxWidthStyle()">
                        <div class="m-0 p-0" [ngStyle]="getMaxWidthStyle()">
                          <datagrid-table-editor
                            (answerChangeEvent)="onAnswerChanged()"
                            [dataGrid]="changeDataGridModeToView(activeQuestion)"
                            [isFormView]="isFormView"
                            [isSMView]="isSMView"
                            [ngStyle]="{
                              width: screenSize.isXLargeSize() || screenSize.isLargeSize() ? '49vw' : '75vw',
                              'max-width': '100%',
                              'box-sizing': 'border-box',
                              'overflow-x': 'auto'
                            }"
                          ></datagrid-table-editor>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ps-2 ms-0 my-3">
                    <button
                      class="btn primary-button"
                      [disabled]="!activeQuestion.isRelevant"
                      (click)="openFileExtractor()"
                    >
                      {{ locale('locale_key.general.buttons.extract_from_file') }}
                    </button>
                  </div>
                </ng-container>
              </div>
              <file-upload-picker
                *ngIf="isFileExtractor"
                [destTableService]="toCopyTableService"
                [isMappingToTaxonomy]="false"
                (dataExtractedEvent)="dataExtracted($event)"
                (closeEvent)="closeFileExtractor()"
              ></file-upload-picker>
              <div *ngIf="requestService.isMultipleChoiceQues(activeQuestion)">
                <div class="me-auto mx-1 py-2" *ngFor="let option of activeQuestion['options']; let oi = index">
                  <div
                    id="optionHeading{{ oi }}"
                    [ngClass]="{ 'accordion-toggle': option.desc }"
                    class="mt-1 dropdown"
                    data-bs-toggle="collapse"
                    attr.data-bs-target="#option-collapse{{ oi }}"
                    aria-expanded="true"
                    attr.aria-controls="option-collapse{{ oi }}"
                  >
                    <input
                      (input)="onAnswerChanged()"
                      [disabled]="!activeQuestion.isRelevant"
                      type="checkbox"
                      class="me-3 d-inline mb-2"
                      id="{{ option.id }}"
                      style="position: relative; bottom: 1px"
                      [checked]="isOptionSelected(option.id)"
                      (change)="addOption(option.id, $event)"
                    />
                    <label class="d-inline cursor" for="{{ option.id }}">{{ option.value }}</label>
                  </div>
                  <div
                    id="option-collapse{{ oi }}"
                    class="accordion-collapse collapse"
                    *ngIf="option.desc"
                    attr.aria-labelledby="optionHeading{{ oi }}"
                  >
                    <i class="text-muted multiline ps-3">{{ option.desc }}</i>
                  </div>
                </div>
                <div
                  *ngIf="
                    activeQuestion.newAnswer.isSubmitted &&
                    activeQuestion.newAnswer.isInvalid(true) &&
                    activeQuestion.required
                  "
                  class="invalid-feedback d-inline"
                >
                  {{ requiredQuestionMessage }}
                </div>
              </div>
              <div *ngIf="requestService.isSingleChoiceQues(activeQuestion)">
                <div class="me-auto mx-1 py-2" *ngFor="let option of activeQuestion['options']; let oi = index">
                  <div
                    id="optionHeading{{ oi }}"
                    [ngClass]="{ 'accordion-toggle': option.desc }"
                    class="mt-1 dropdown"
                    data-bs-toggle="collapse"
                    attr.data-bs-target="#option-collapse{{ oi }}"
                    aria-expanded="true"
                    attr.aria-controls="option-collapse{{ oi }}"
                  >
                    <input
                      (input)="onAnswerChanged()"
                      [disabled]="!activeQuestion.isRelevant"
                      type="radio"
                      class="me-3 d-inline mp-50"
                      style="position: relative; bottom: 1px"
                      [value]="option.id"
                      [id]="option.id"
                      [(ngModel)]="activeQuestion.newAnswer['selectedOptionId']"
                    />
                    <label class="d-inline cursor" for="{{ option.id }}">{{ option.value }}</label>
                  </div>
                  <div
                    id="option-collapse{{ oi }}"
                    class="accordion-collapse collapse"
                    *ngIf="option.desc"
                    attr.aria-labelledby="optionHeading{{ oi }}"
                  >
                    <i class="text-muted multiline ps-3">{{ option.desc }}</i>
                  </div>
                </div>
                <div
                  *ngIf="
                    activeQuestion.newAnswer.isSubmitted &&
                    activeQuestion.newAnswer.isInvalid(true) &&
                    activeQuestion.required
                  "
                  class="invalid-feedback d-inline"
                >
                  {{ requiredQuestionMessage }}
                </div>
              </div>
            </div>
          </div>

          <div class="col border-t pt-3 mt-2 ms-0 ps-0" *ngIf="activeQuestion.comments">
            <label class="mt-1">{{ locale('locale_key.pages.task_portal.answer_wizard.comments.title') }}</label>
            <textarea
              (input)="onAnswerChanged()"
              class="form-control w-50"
              rows="1"
              [(ngModel)]="activeQuestion.newAnswer.comments"
              (keyup)="autoGrowTextZone($event)"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--view 3-->
  <div
    class="d-flex col-12 mt-0 pt-0 mx-0"
    *ngIf="viewNo == 3"
    style="box-sizing: border-box"
    style="height: var(--page-content-minus-4-page-header-footer)"
  >
    <div class="py-3 scroll-y w-100" [ngClass]="screenSize.isSmallerSize() ? 'px-0' : 'px-3'">
      <h4
        class="pb-3"
        [ngClass]="{
          'ps-3': screenSize.isXLargeSize() || screenSize.isLargeSize(),
          'ps-0': screenSize.isSmallerSize()
        }"
      >
        <i class="las la-list-alt fs-3" *ngIf="screenSize.isXLargeSize() || screenSize.isLargeSize()"></i>
        {{ locale('locale_key.pages.task_portal.title.review_questionnaire') }}
      </h4>
      <div class="scroll-y">
        <ng-container *ngFor="let question of questions; let i = index">
          <div
            class="pb-3 row"
            *ngIf="isQuesVisible(question)"
            [ngClass]="{
              'ps-5': screenSize.isXLargeSize() || screenSize.isLargeSize(),
              'ps-0': screenSize.isSmallerSize()
            }"
          >
            <div class="thin-border-b pb-3">
              <h5 class="mt-2 bold" [ngClass]="{ nonRelevantQuestion: !question.isRelevant }">
                <ng-container
                  *ngTemplateOutlet="nonRelevantQuestionIcon; context: { isRelevant: question.isRelevant }"
                ></ng-container>
                {{ questionsRefNumber.get(question.id) }} {{ question.question }}
                <sup class="text-danger">{{ question.required ? '*' : '' }}</sup>
                <i
                  *ngIf="question.isRelevant"
                  class="la la-edit fs-4 mb-auto mt-0 pt-0 ms-2"
                  (click)="editQuestion(question, i)"
                ></i>
              </h5>
              <span *ngIf="question.newAnswer?.isInvalid?.(question.required)" class="highlight-validation-message">
                {{ requiredQuestionMessage }}
              </span>
              <div *ngIf="shouldShowCsrdLegislationLabel(question)">
                <ng-container *ngTemplateOutlet="csrdInfoBadge; context: { question }"></ng-container>
              </div>
              <div
                [ngStyle]="{ 'margin-left': shouldShowCsrdLegislationLabel(question) ? '120px' : '0px' }"
                [ngClass]="{ 'mt-3': shouldShowCsrdLegislationLabel(question) }"
              >
                <span class="text-muted">
                  <div class="d-flex align-items-center" *ngIf="question.newAnswer.hasAnyValue()">
                    <i class="gradient-end bold me-2 fs-5">{{ locale('locale_key.pages.task_portal.answer') }}</i>
                    <ng-container *ngIf="requestService.isMultipleChoiceQues(question)">
                      <div class="d-flex" *ngFor="let optionId of question.newAnswer.selectedOptionIds">
                        <input type="checkbox" class="mx-1" checked disabled />
                        <label class="ps-2">{{ question.getOption(optionId).value }}</label>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="requestService.isSingleChoiceQues(question)">
                      <div class="d-flex" *ngIf="question.newAnswer.selectedOptionId">
                        <input type="radio" class="mx-1" checked disabled />
                        <label class="ps-2">{{ question.getOption(question.newAnswer.selectedOptionId).value }}</label>
                      </div>
                    </ng-container>
                    <label *ngIf="requestService.isNumberQues(question)">
                      {{ question.newAnswer.value + ' ' + getUnitString(question.newAnswer.unit) }}
                    </label>
                    <div class="d-flex" *ngIf="requestService.isDateQues(question)">
                      <h5 class="text-muted d-inline">{{ question.newAnswer.getDateFromString() }}</h5>
                      <h5 class="mx-1" *ngIf="question.range">-</h5>
                      <h5 class="text-muted d-inline" *ngIf="question.range">
                        {{ question.newAnswer.getDateToString() }}
                      </h5>
                    </div>
                    <h5 class="text-muted m-0 d-inline multiline" *ngIf="requestService.isTextQues(question)">
                      {{ question.newAnswer.text }}
                    </h5>
                    <label
                      class="text-muted m-0 d-inline multiline"
                      *ngIf="requestService.isEmissionFactorQues(question)"
                    >
                      <emission-factor [selectedEF]="question.newAnswer.selectedEmissionFactor"></emission-factor>
                    </label>
                    <div
                      class="py-3 w-50 open-bx"
                      *ngIf="
                        (requestService.isAttachmentQues(question) && question.newAnswer.attachmentStorageId) ||
                        requestService.isDataTableQues(question)
                      "
                    >
                      <div class="ball-pulse loader-primary" *ngIf="question.newAnswer.isLoading">
                        <div style="background-color: var(--main-dark-color1)"></div>
                        <div style="background-color: var(--main-dark-color1)"></div>
                        <div style="background-color: var(--main-dark-color1)"></div>
                      </div>
                      <ng-container *ngIf="!question.newAnswer.isLoading">
                        <div class="col-2 text-left mx-0 px-0">
                          <i
                            class="la la-file-text-o fs-1 me-auto ms-0 ps-0"
                            *ngIf="requestService.isAttachmentQues(question)"
                          ></i>
                          <i
                            class="la la-table fs-1 me-auto ms-0 ps-0"
                            *ngIf="requestService.isDataTableQues(question)"
                          ></i>
                        </div>
                        <div class="col-8 d-flex justify-content-center text-start ms-0 ps-0 text-truncate">
                          <p
                            class="text-muted my-auto me-auto text-truncate"
                            data-bs-toggle="tooltip"
                            data-placement="top"
                            title="{{
                              requestService.isDataTableQues(question)
                                ? 'Data Table'
                                : question.newAnswer['attachmentLabel']
                            }}"
                          >
                            {{
                              requestService.isDataTableQues(question)
                                ? locale('locale_key.pages.data_request.question_type.data_table')
                                : question.newAnswer['attachmentLabel']
                            }}
                          </p>
                        </div>
                        <div class="col-2 d-flex justify-content-center">
                          <p
                            class="text-muted m-auto underline cursor me-2"
                            (click)="
                              requestService.isDataTableQues(question)
                                ? openTableFile(question.newAnswer, question)
                                : openFile(question.newAnswer)
                            "
                          >
                            {{ locale('locale_key.general.buttons.open') }}
                          </p>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <small class="text-muted" *ngIf="question.newAnswer.comments">
                    <i class="gradient-end bold me-2 fs-5">
                      {{ locale('locale_key.pages.data_request.comments.placeholder') }}:
                    </i>
                    {{ question.newAnswer.comments }}
                  </small>
                </span>
              </div>
            </div>
          </div>
        </ng-container>
        <div
          class="col-12 mt-1"
          [ngClass]="{
            'px-1 mx-2': screenSize.isXLargeSize() || screenSize.isLargeSize(),
            'ps-0': screenSize.isSmallerSize() || screenSize.isMediumSize()
          }"
        >
          <label class="mt-1" [ngClass]="{ 'ms-4': screenSize.isXLargeSize() || screenSize.isLargeSize() }">
            {{ locale('locale_key.pages.task_portal.answer_wizard.comments.title') }}
          </label>
          <textarea
            (input)="onAnswerChanged()"
            class="form-control w-75"
            [ngClass]="{ 'ms-4': screenSize.isXLargeSize() || screenSize.isLargeSize() }"
            rows="3"
            [(ngModel)]="message"
            placeholder="{{ locale('locale_key.pages.task_portal.answer_wizard.message.placeholder') }}"
            (keyup)="autoGrowTextZone($event)"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
  <!-- other buttons-->
  <div
    class="card-footer p-3 w-100 mt-auto mb-0 d-flex flex-row justify-content-between bg-selected text-wrap"
    *ngIf="submitted"
  >
    <span>
      <i class="la la-send-o fs-4 me-2" style="position: relative; top: 2px"></i>
      {{ locale('locale_key.pages.task_portal.data_submitted_desc') }}
    </span>
    <button class="btn primary-button" (click)="close()" *ngIf="viewNo != 1 && isFromInboxPage">
      {{ locale('locale_key.pages.task_portal.button.return_to_inbox') }}
    </button>
  </div>
  <!-- button footer -->
  <div
    class="card-footer p-1 w-100 mt-auto mb-0 d-flex flex-row justify-content-between text-wrap"
    *ngIf="!submitted && viewNo != 1"
    style="height: var(--pages-header-footer-height)"
  >
    <div class="m-0 p-0 d-flex flex-row me-3">
      <button
        type="button"
        class="btn tertiary-button custom-button me-2 p-1"
        (click)="openModal(reUseAnswerModal, 'modal-md')"
        *ngIf="isReuseAnswers() && viewNo == 2"
        style="font-size: 12px !"
      >
        {{ locale('locale_key.pages.task_portal.answer_wizard.button.reuse_answers') }}
      </button>
      <i
        class="la la-info-circle info-popover-arrow ms-2 pt-2"
        [title]="locale('locale_key.pages.task_portal.reanswer.tooltip')"
        *ngIf="isReuseAnswers() && viewNo == 2"
        type="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      ></i>
      <button
        type="button"
        class="btn tertiary-button custom-button p-1"
        (click)="startReview()"
        *ngIf="isReviewedOnce && viewNo == 2"
      >
        {{ locale('locale_key.general.buttons.review') }}
      </button>
    </div>
    <ng-container *ngIf="!loadingInProgress">
      <div class="m-0 p-0 me-3">
        <button
          type="button"
          class="btn tertiary-button custom-button mx-1 p-1"
          *ngIf="viewNo >= 2"
          (click)="openModal(draftConfirmation, 'modal-md')"
        >
          {{ locale('locale_key.button.save_as_draft') }}
        </button>
        <button
          type="button"
          class="btn secondary-button custom-button mx-1 p-1"
          (click)="prevQuestion()"
          *ngIf="questions.length > 0 && viewNo != 3 ? activeQuestionIndex != 0 : true"
        >
          {{ locale('locale_key.general.buttons.previous') }}
        </button>
        <button
          type="button"
          class="btn primary-button custom-button mx-1 p-1"
          (click)="nextQuestion()"
          *ngIf="viewNo == 2"
        >
          {{ locale('locale_key.general.buttons.next') }}
        </button>
        <button
          type="button"
          [disabled]="viewNo == 3 && !isQuestionnaireReadyForSubmission"
          class="btn primary-button custom-button mx-1 p-1"
          (click)="openModal(confirmSubmission, 'modal-md')"
          *ngIf="viewNo == 3"
        >
          {{ locale('locale_key.general.buttons.submit') }}
        </button>
      </div>
    </ng-container>

    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
    <!-- <button type="button" class="btn primary-button custom-button" (click)="close()" *ngIf="viewNo == 3 && !isStartNewAnswer">{{ locale("locale_key.general.buttons.close") }}</button> -->
  </div>
</div>

<ng-template #previewQuestionnaireModal>
  <div style="background-color: var(--main-dark-color1)" class="modal-header alert-modal-header">
    <span class="text-white mb-0 pb-0 ms-2 mb-0">
      {{ locale('locale_key.pages.task_portal.questionnaire_preview') }}
    </span>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="text-white las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="previewQuestionnaireBody; context: { shouldCloseModal: true }"></ng-container>
  </div>
  <div class="modal-footer" style="border-top: none">
    <button type="button" class="btn tertiary-button custom-button p-1 mx-1" (click)="closeModal()">
      {{ locale('locale_key.general.buttons.close') }}
    </button>
  </div>
</ng-template>

<ng-template #previewQuestionnaireBody let-shouldCloseModal="shouldCloseModal">
  <div class="accordion scroll" id="previewWrap" style="border: none !important; height: 100%">
    <div
      class="accordion level-1"
      style="border: none !important; height: 100%; overflow-y: scroll; overflow-x: hidden !important"
    >
      <ng-container *ngFor="let section of task.questionnaire.sections; let i = index">
        <div class="d-flex flex-row my-auto p-2 bg-comp-grey ms-3 my-2">
          <h5 class="my-auto ms-2 d-inline me-4" style="min-width: fit-content !important">
            {{ locale('locale_key.modal.questionnaire_preview.section_number', { sectionNumber: i + 1 }) }}
          </h5>
        </div>
        <ng-container *ngFor="let question of section.getQuestionsWithoutParent(); let j = index">
          <ng-container
            *ngTemplateOutlet="
              questionTemplate;
              context: { section: section, question: question, index: j, level: 1, parentNumber: '' }
            "
          ></ng-container>
        </ng-container>
      </ng-container>
    </div>

    <ng-template
      #questionTemplate
      let-section="section"
      let-question="question"
      let-index="index"
      let-level="level"
      let-parentNumber="parentNumber"
    >
      <div class="accordion" id="accordionQuestions">
        <div
          class="accordion-item"
          style="border: none !important; padding-left: {{ level === 1 ? '15px' : '15px' }}; margin-bottom: 0;"
        >
          <h2
            class="accordion-header thin-border"
            style="border-radius: 5px; margin-bottom: 0"
            [id]="'questionHeading' + question.id"
          >
            <button
              class="questionbox"
              [class.accordion-button]="question.isFollowUpQues"
              [class.collapsed]="!followupsExpandedInPreview[question.id]"
              type="button"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#questionCollapse' + question.id"
              [attr.aria-expanded]="followupsExpandedInPreview[question.id]"
              [attr.aria-controls]="'questionCollapse' + question.id"
              style="margin-bottom: 0; border-bottom: none"
            >
              <div class="d-flex flex-column flex-grow-1">
                <div class="text-wrap px-2 w-75">
                  <h5 class="mt-2 bold" *ngIf="section.ifAnswerIsStringForFollowup(question)">
                    If the answer is ({{ section.ifAnswerIsStringForFollowup(question) }})
                  </h5>
                  <h5 class="mt-2" [ngClass]="{ nonRelevantQuestion: !question.isRelevant }">
                    <ng-container
                      *ngTemplateOutlet="nonRelevantQuestionIcon; context: { isRelevant: question.isRelevant }"
                    ></ng-container>
                    <span class="me-1">{{ questionsRefNumber.get(question.id) }}</span>
                    {{ question.question }}
                  </h5>
                </div>

                <div class="m-1 d-flex flex-row">
                  <span>
                    <a
                      class="underline text-muted mt-2"
                      *ngIf="section.hasChildren(question)"
                      (click)="toggleFollowupQuestion(section, question, $event)"
                    >
                      {{
                        followupsExpandedInPreview[question.id]
                          ? slocale('Hide follow up questions')
                          : slocale('Show follow up questions')
                      }}
                    </a>
                  </span>
                  <div *ngIf="shouldShowCsrdLegislationLabel(question)">
                    <span *ngIf="section.hasChildren(question)" class="m-2">|</span>
                    <ng-container
                      *ngTemplateOutlet="
                        csrdInfoBadge;
                        context: {
                          question,
                          shouldCloseModal
                        }
                      "
                    ></ng-container>
                  </div>
                </div>
              </div>
              <span *ngIf="question.isRelevant" class="col-2">
                <button
                  class="btn tertiary-button custom-button my-auto h-auto ms-auto p-1 me-2"
                  *ngIf="answerActivity?.draft"
                  [disabled]="!isQuesVisible(question)"
                  (click)="startAnswering(question, shouldCloseModal)"
                >
                  {{ getButtonTextStartOrResume(question) }}
                </button>
                <button
                  class="btn tertiary-button custom-button my-auto h-auto ms-auto p-1 me-2"
                  *ngIf="answerActivity?.draft == false"
                  [disabled]="!isQuesVisible(question)"
                  (click)="startAnswering(question, shouldCloseModal)"
                >
                  {{
                    question.answer?.hasAnyValue()
                      ? locale('locale_key.general.buttons.review')
                      : locale('locale_key.general.buttons.start')
                  }}
                </button>
                <button
                  class="btn custom-button my-auto h-auto ms-auto p-1 me-2"
                  [ngClass]="
                    isTheFirstRelevantQuestionInASection(section, question.id) ? 'primary-button' : 'tertiary-button'
                  "
                  *ngIf="!answerActivity"
                  [disabled]="!isTheFirstRelevantQuestionInASection(section, question.id)"
                  (click)="startAnswering(question, shouldCloseModal)"
                >
                  {{ locale('locale_key.general.buttons.start') }}
                </button>
              </span>
            </button>
          </h2>
          <div
            [id]="'questionCollapse' + question.id"
            class="accordion-collapse collapse"
            [class.show]="followupsExpandedInPreview[question.id]"
            [attr.aria-labelledby]="'questionHeading' + question.id"
            data-bs-parent="#accordionQuestions"
          >
            <div class="accordion-body p-0 mb-0">
              <i
                class="la la-undo"
                style="transform: rotate(180deg); position: relative; top: 0px"
                *ngIf="section.hasChildren(question)"
              ></i>
              <p *ngIf="!section.hasChildren(question)" class="m-5">
                This question does not have a follow up question!
              </p>

              <ng-container *ngIf="followupsExpandedInPreview[question.id]">
                <ng-container *ngFor="let subQuestion of section.getChildrenOfParent(question); let j = index">
                  <ng-container
                    *ngTemplateOutlet="
                      questionTemplate;
                      context: {
                        section: section,
                        question: subQuestion,
                        index: j,
                        level: level + 1,
                        parentNumber: parentNumber + (index + 1) + '.'
                      }
                    "
                    style="position: relative; top: -10px !important"
                  ></ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <div
    class="card-footer px-3 py-2 z-1000 w-100 mt-auto mb-0 d-flex flex-row justify-content-between bg-warning text-wrap"
    *ngIf="!submitted && task.questionnaire.status == 'REJECTED'"
  >
    <span>
      <i class="la la-warning fs-4 me-2" style="position: relative; top: 2px"></i>
      {{ locale('locale_key.pages.task_portal.resubmit_the_answers_desc', { name: task.getRequesterName() }) }}
    </span>
  </div>
</ng-template>

<ng-template #fileViewer>
  <file-viewer
    [inputFile]="selectedFile"
    [destTable]="activeQuestion.newAnswer['dataGridService'].table"
    [isMappingToTaxonomy]="false"
    (dataExtractedEvent)="dataExtracted($event)"
    (closeModalEvent)="closeModal()"
  ></file-viewer>
</ng-template>

<ng-template #upload>
  <div class="main-modal-header modal-header py-0 px-3">
    <h4 class="modal-title text-white">
      {{ locale('locale_key.pages.file_explorer.upload_modal.button.upload_files') }}
    </h4>
    <button type="button" class="ms-auto btn close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="false" class="list-inline text-white">
        <a (click)="closeFileUploader()">
          <i class="las la-times text-white" style="color: text-white !important"></i>
        </a>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mt-0 px-1" *ngIf="!fileToUpload">
      <div class="col-sm-32">
        <div class="hoverable dropZone mt-0 py-3 mb-2">
          <div class="card-body border border-dotted" id="dotted">
            <input
              type="file"
              id="uploadCaptureInputFile"
              (change)="selectFilesForUpload($event, fileViewer)"
              title=" "
            />
            <h5 class="mt-2">{{ locale('locale_key.pages.file_explorer.upload_modal.drag_drop.title') }}</h5>
          </div>
          <h5 class="mt-2">{{ locale('locale_key.pages.file_explorer.upload_modal.or') }}</h5>
          <div class="row justify-content-center">
            <input
              type="file"
              id="uploadCaptureInputFile"
              #fileInput
              style="display: none"
              (change)="selectFilesForUpload($event, fileViewer)"
              title=" "
            />
            <button id="browsefilebutton" (click)="fileInput.click()" class="btn btn-block custom-button">
              {{ locale('locale_key.pages.file_upload_picker.button.browse_files') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row px-1 mh-200">
      <div class="col-sm-32">
        <div class="files-list">
          <div class="single-file" *ngIf="fileToUpload">
            <div class="fonticon-container">
              <div class="fonticon-wrap me-0 mb-0">
                <i class="las la-file-medical"></i>
              </div>
            </div>
            <div class="info">
              <label class="fonticon-classname mb-0">{{ fileToUpload?.name }}</label>
              &nbsp;
              <label class="fonticon-unit mb-0">{{ formatBytes(fileToUpload?.size) }}</label>
              <div class="progress form-group me-0 ms-0">
                <div class="progress-bar" role="progressbar" [style.width.%]="uploadProgress"></div>
              </div>
              <h4 class="name">
                {{ fileUploadMessages }}
              </h4>
            </div>
            <div class="delete" *ngIf="uploadProgress == 0" (click)="deleteFileFromUpload()">
              <i class="ft-trash-2"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form class="uploadForm" method="post" enctype="multipart/form-data">
    <div class="modal-footer">
      <ng-container *ngIf="!loadingInProgress">
        <button type="button" class="btn secondary-button custom-button" (click)="closeFileUploader()">
          {{ locale('locale_key.general.buttons.close') }}
        </button>
        <button
          (click)="uploadFiles()"
          type="submit"
          class="btn btn-add custom-button"
          [disabled]="isDisabled"
          *ngIf="isUploading"
        >
          <i class="fa fa-user"></i>
          {{ locale('locale_key.pages.file_explorer.upload_modal.button.upload_files') }}
        </button>
      </ng-container>
      <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #confirmSubmission>
  <div class="modal-header" style="border-bottom: none">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0">
      {{ locale('locale_key.pages.task_portal.submission_confirmation_modal.title') }}
    </h4>
    <button type="button" class="btn close" (click)="closeModal()">
      <span aria-hidden="true" class="black" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="modal-body my-2">
    <h5>{{ locale('locale_key.pages.task_portal.submit_answers_confirmation_modal.desc') }}</h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <div class="ball-pulse loader-primary text-center m-auto" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn secondary-button custom-button" data-dismiss="modal" (click)="closeModal()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn primary-button custom-button" (click)="submit()">
        {{ locale('locale_key.general.buttons.yes') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<ng-template #confirmCancel>
  <div class="modal-header" style="border-bottom: none">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0">
      {{ locale('locale_key.pages.task_portal.cancel_submission_confirmation_modal.title') }}
    </h4>
    <button type="button" class="btn close" (click)="closeModal()">
      <span aria-hidden="true" class="black" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="modal-body my-2">
    <h5>{{ locale('locale_key.pages.task_portal.cancel_submission_confirmation_modal.desc') }}</h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" data-dismiss="modal" (click)="closeModal()">
        {{ locale('locale_key.pages.task_portal.button.dont_save') }}
      </button>
      <button type="button" class="btn primary-button custom-button" (click)="cancelSubmission()" data-dismiss="modal">
        {{ locale('locale_key.general.buttons.yes') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<ng-template #reUseAnswerModal>
  <div class="main-modal-header modal-header">
    <h4 class="modal-title text-white" *ngIf="!isSecondView">
      {{ locale('locale_key.pages.task_portal.reuse_answer_modal.title', { title: task.requestGroup.title }) }}
    </h4>
    <div class="d-flex flex-row col-11" *ngIf="isSecondView">
      <div class="col-6 px-0">
        <h4 class="modal-title text-white">{{ selectedPrevTask.requestGroup.title }}</h4>
      </div>
      <div class="col-6 text-end my-auto">
        <span style="font-size: 10px">{{ selectedPrevTask.getTitle() }}</span>
        <span class="my-auto" style="font-size: 10px">{{ selectedPrevTask.requestGroup.getCreated() }}</span>
        -
        <span class="my-auto" style="font-size: 10px">{{ selectedPrevTask.requestGroup.getDeadline() }}</span>
      </div>
    </div>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close" *ngIf="!loadingInProgress">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body scroll mh-600" style="border-top: none; overflow: scroll; max-height: 65vh !important">
    <div class="col-lg-12 mt-0 pt-0">
      <div class="row px-1 mt-0 pt-0">
        <div class="w-100" *ngIf="!isSecondView">
          <div class="row">
            <p class="text-start my-2">{{ locale('locale_key.pages.task_portal.reuse_answer_modal.title2') }}</p>
          </div>

          <ng-container *ngFor="let task of recurringAnsweredTasks(); let i = index">
            <div class="row d-flex flex-row justify-content-between align-center px-0 mx-0 w-100">
              <div
                class="row mx-auto my-1 py-3 mb-2 d-flex flex-row justify-content-between row-design"
                style="width: 95%"
              >
                <div class="col-4">
                  <span class="dark-text me-auto pt-1">{{ task.getTitle() }}</span>
                </div>
                <div class="col-5 bold">
                  <span class="my-auto">{{ task.requestGroup.getCreated() }}</span>
                  -
                  <span class="my-auto">{{ task.requestGroup.getDeadline() }}</span>
                </div>
                <!-- <div class="col-5" *ngIf="!task.requestGroup.isChildRecurring">
                  <span class="my-auto"> {{ task.requestGroup.getCreated() }}</span> - <span class="my-auto"> {{ task.requestGroup.getDeadline() }} </span>
                </div> -->
                <div class="col-3">
                  <span class="underline ms-0 cursor pl-1 dark-text" (click)="showAnswer(task)">
                    {{ locale('locale_key.pages.task_portal.reuse_answer_modal.button.view_answer') }}
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="w-100" *ngIf="isSecondView">
          <data-supplier-view-answer
            [task]="selectedPrevTask"
            [answerActivity]="answerActivityOfPrevTask"
          ></data-supplier-view-answer>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="border: none">
    <ng-container *ngIf="!loadingInProgress">
      <button
        type="button"
        *ngIf="isSecondView"
        class="btn tertiary-button custom-button"
        (click)="isSecondView = false"
        style="position: absolute; left: 1rem"
      >
        {{ locale('locale_key.pages.task_portal.reuse_answer_modal.button.back') }}
      </button>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">
        {{ locale('locale_key.pages.task_portal.reuse_answer_modal.button.close') }}
      </button>
      <button type="button" *ngIf="isSecondView" class="btn primary-button custom-button" (click)="useAnswers()">
        {{ locale('locale_key.pages.task_portal.reuse_answer_modal.button.use_answer') }}
      </button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>

<!-- Auto save modal -->
<ng-template #autoSaveModal>
  <app-auto-save-answers-modal
    [shouldShowAutoSaveModal]="shouldShowAutoSaveModal"
    [loadingInProgress]="loadingInProgress"
    [isAutoSavingAnswersFailed]="isAutoSavingAnswersFailed"
    (closeModalEvent)="closeModal()"
    (completeAnsweringEvent)="markAutoSaveQuestionnaireComplete()"
    (retrySavingAnswers)="submit(true, true)"
  ></app-auto-save-answers-modal>
</ng-template>

<!-- Save questionnaire modal -->
<ng-template #draftConfirmation>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel12">
      <i class="la la-exclamation-circle fs-4" style="color: var(--warning-color)"></i>
      {{ locale('locale_key.pages.task_portal.button.save_as_draft') }}
    </h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="close()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <h5>{{ locale('locale_key.pages.task_portal.save_as_draft.desc') }}</h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <div class="ball-pulse loader-primary text-center m-auto" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn btn-add text-white custom-button" (click)="submit(true)">
        {{ locale('locale_key.general.buttons.save') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<!-- Exit questionnaire modal -->
<ng-template #exitQuestionnaireModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel12">
      <i class="la la-exclamation-circle fs-4" style="color: var(--warning-color)"></i>
      {{ locale('locale_key.pages.task_portal.save_before_leaving') }}
    </h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <h5>{{ locale('locale_key.pages.task_portal.save_before_leaving.desc') }}</h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <div class="ball-pulse loader-primary text-center m-auto" *ngIf="isLoading">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
    <ng-container *ngIf="!isLoading">
      <button type="button" class="btn secondary-button" (click)="closeModal()">
        {{ locale('locale_key.pages.task_portal.button.return_to_page') }}
      </button>
      <button type="button" class="btn btn-add text-white custom-button" (click)="close()">
        {{ locale('locale_key.pages.insight_detail.leave_page') }}
      </button>
    </ng-container>
  </div>
</ng-template>

<app-chat-box *ngIf="isChatOpen" [requestTask]="task" (chatClosed)="isChatOpen = false"></app-chat-box>

<!-- Confirm delete modal-->
<ng-template #confirmDelete>
  <div class="modal-header alert-modal-header">
    <h5 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel12">{{ slocale('Remove answer entry') }}</h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="my-2">{{ slocale('The answer entry will be permanently removed and cannot be restored.') }}</p>
    <p>{{ slocale('Are you sure you want to proceed?') }}</p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <div class="ball-pulse loader-primary text-center m-auto" *ngIf="isLoading">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
    <ng-container *ngIf="!isLoading">
      <button type="button" class="btn secondary-button" (click)="closeModal()">
        {{ locale('locale_key.general.buttons.cancel') }}
      </button>
      <button type="button" class="btn btn-danger text-white" (click)="close()">Remove round</button>
    </ng-container>
  </div>
</ng-template>

<ng-template #csrdInfoBadge let-question="question" let-shouldCloseModal="shouldCloseModal">
  <span
    class="csrd-badge w-auto py-auto my-auto text-nowrap non-collapsing"
    type="button"
    role="button"
    [ngClass]="{
      nonRelevantQuestion: !question.isRelevant,
      'disabled-badge': shouldCloseModal,
      'csrd-badge-border': !shouldCloseModal,
      badge: !shouldCloseModal,
      'px-2': shouldCloseModal
    }"
    (click)="shouldCloseModal ? null : openCSRDQuestionInfo(question, shouldCloseModal)"
    data-bs-toggle="collapse"
    data-bs-target
  >
    {{ getCsrdLegislationLocationLabel(question) }}
  </span>
</ng-template>

<ng-template #nonRelevantQuestionIcon let-isRelevant="isRelevant" let-disableTooltip="disableTooltip">
  <i
    class="la la-ban size-15 me-1"
    *ngIf="!isRelevant"
    style="position: relative; top: 2px"
    [attr.data-bs-toggle]="!disableTooltip && 'tooltip'"
    [attr.title]="!disableTooltip ? 'This question is not relevant for you, no response is needed.' : null"
  ></i>
</ng-template>

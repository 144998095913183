import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { DashboardComponent } from '../components/dashboard/dashboard.component'
import { LoginComponent } from '../components/auth/login.component'
import { VisitorAuthGuardFe } from '../services/VisitorAuthGuardFe'
import { CacheStateGuardFe } from '../services/CacheStateGuardFe'
import { FilePortalComponent } from '../components/file/file-portal.component'
import { DataPortalComponent } from '../components/data/data-portal.component'
import { RegisterComponent } from '../components/register/register.component'
import { ResetRequestComponent } from '../components/reset-request/reset-request.component'
import { ForgotpasswordComponent } from '../components/forgotpassword/forgotpassword.component'
import { ResetforgotpasswordComponent } from '../components/resetforgotpassword/resetforgotpassword.component'
import { EmissionPortalComponent } from '../components/emission/emission-portal.component'
import { TaxonomyComponent } from '../components/taxonomy/taxonomy.component'
import { OrganizationComponent } from '../components/organization/organization.component'
import { NotificationComponent } from '../components/notification/notification.component'
import { HomeComponent } from '../components/home/home.component'
import { ReferenceWidgetsComponent } from '../components/reference-widgets/reference-widgets.component'
import { DetailsComponent } from '../components/details/details.component'
import { WorkspacesComponent } from '../components/workspaces/workspaces.component'
import { RoutesFe } from './RoutesFe'
import { WorkspaceLayoutComponent } from '../components/layout/workspace-layout/workspace-layout.component'
import { OpenLayoutComponent } from '../components/layout/open-layout/open-layout.component'
import { PreWorkspaceLayoutComponent } from '../components/layout/preworkspace-layout/preworkspace-layout.component'
import { CacheLoadingProgressComponent } from '../components/cache-loading/cache-loading-progress.component'
import { VerifySharedDashboardComponent } from '../components/verify/verrifySharedDashboard.component'
import { AuthGuardFe } from '../services/AuthGuardFe'
import { EmailTableComponent } from '../components/admin-portal/email-table/email-table.component'
import { ClientsComponent } from '../components/admin-portal/clients/clients.component'
import { CustomerSuccessComponent } from '../components/admin-portal/admins/customer-success.component'
import { NetworkPortalComponent } from '../components/network/network.component'
import { DataHubComponent } from '../components/data-hub/data-hub.component'
import { RequestDraftComponent } from '../components/setup/drafts/drafts.component'
import { SMInboxComponent } from '../components/setup/inbox/inbox.component'
import { RequestsComponent } from '../components/setup/requests/requests.component'
import { DOInboxComponent } from '../components/dataReporter/inbox/inbox.component'
import { DORequestsComponent } from '../components/dataReporter/requests/requests.component'
import { DataCategoriesComponent } from '../components/data-categories/data-categories.component'
import { UnitSystemsComponent } from '../components/unit-systems/unit-systems.component'
import { InsightsHubComponent } from '../components/insights-hub/insights-hub.component'
import { ViewTemplateComponent } from '../components/settings/company-settings/view-template/view-template.component'
import { SettingsComponent } from '../components/settings/settings.component'
import { CompanySettingsComponent } from '../components/settings/company-settings/company-settings.component'
import { RotatePasswordComponent } from '../components/passwordrotation/rotatepassword.component'
import { PasswordRotationGuardFe } from '../services/PasswordRotationGuardFe'
import { ProjectsComponent } from '../components/projects/projects.component'
import { SaveAnswersGuard } from '../routeGuard/save-answers.guard'

export const VISITOR_LOADING_ROUTE = {
  path: RoutesFe.LOADING.childPath(),
  component: CacheLoadingProgressComponent,
  canActivate: [VisitorAuthGuardFe]
}
export const VISITOR_SHARED_DASHBOARD_ROUTE = {
  path: RoutesFe.VISITOR_SHARED_DASHBOARD.childPath(),
  component: DashboardComponent,
  canActivate: [VisitorAuthGuardFe]
}

export const WORKSPACES_ROUTE = {
  path: RoutesFe.WORKSPACES.childPath(),
  component: WorkspacesComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe]
}
export const LOADING_ROUTE = {
  path: RoutesFe.LOADING.childPath(),
  component: CacheLoadingProgressComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe]
}
export const REFERENCE_WIDGETS_COMPONENT = {
  path: RoutesFe.REFERENCE_WIDGETS.childPath(),
  component: ReferenceWidgetsComponent,
  canActivate: [AuthGuardFe]
}
export const ROTATE_PASS_ROUTE = {
  path: RoutesFe.ROTATE_PASS.childPath(),
  component: RotatePasswordComponent,
  canActivate: [AuthGuardFe]
}

export const RESET_PASS_ROUTE = { path: RoutesFe.RESET_PASS.childPath(), component: ResetRequestComponent }
export const LOGIN_ROUTE = { path: RoutesFe.LOGIN.childPath(), component: LoginComponent }
export const FORGOT_PASS_ROUTE = { path: RoutesFe.FORGOT_PASS.childPath(), component: ForgotpasswordComponent }
export const RESET_FORGOT_PASS_ROUTE = {
  path: RoutesFe.RESET_FORGOT_PASS.childPath(),
  component: ResetforgotpasswordComponent
}
export const MANAGER_REGISTRATION_ROUTE = {
  path: RoutesFe.MANAGER_REGISTRATION.childPath(),
  component: RegisterComponent
}
export const PARTNER_REGISTRATION_ROUTE = {
  path: RoutesFe.PARTNER_REGISTRATION.childPath(),
  component: RegisterComponent
}
export const CONTACT_REGISTRATION_ROUTE = {
  path: RoutesFe.CONTACT_REGISTRATION.childPath(),
  component: RegisterComponent
}
export const VERIFY_SHARED_DASHBOARD_ROUTE = {
  path: RoutesFe.VERIFY_SHARED_DASHBOARD.childPath(),
  component: VerifySharedDashboardComponent
}

export const HOME_ROUTE = {
  path: RoutesFe.HOME.childPath(),
  component: HomeComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
export const PROJECTS_ROUTE = {
  path: RoutesFe.PROJECTS.childPath(),
  component: ProjectsComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
export const PROFILE_ROUTE = {
  path: RoutesFe.PROFILE.childPath(),
  component: SettingsComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
export const COPMANY_SETTING_ROUTE = {
  path: RoutesFe.COMPANY_SETTINGS.childPath(),
  component: CompanySettingsComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
export const VIEW_TEMPLATE_ROUTE = {
  path: RoutesFe.VIEW_TEMPLATE.childPath(),
  component: ViewTemplateComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
export const DASHBOARD_ROUTE = {
  path: RoutesFe.DASHBOARD.childPath(),
  component: DashboardComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
// export const USER_KPI_ROUTE = { path: RoutesFe.USER_KPI.childPath(), component: KPI, canActivate: [AuthGuardFe, CacheStateGuardFe]}
export const INSIGHTS_HUB_ROUTE = {
  path: RoutesFe.INSIGHTS_HUB.childPath(),
  component: InsightsHubComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
// export const ADVANCED_KPI_ROUTE = { path: RoutesFe.ADVANCED_KPI.childPath(), component: AdvancedKPI, canActivate: [AuthGuardFe, CacheStateGuardFe] }
export const FILES_ROUTE = {
  path: RoutesFe.FILES.childPath(),
  component: FilePortalComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
export const DATA_ROUTE = {
  path: RoutesFe.DATA.childPath(),
  component: DataPortalComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
export const EMISSION_ROUTE = {
  path: RoutesFe.EMISSION.childPath(),
  component: EmissionPortalComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
export const ORGANIZATION_ROUTE = {
  path: RoutesFe.ORGANIZATION.childPath(),
  component: OrganizationComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
export const TAXONOMY_ROUTE = {
  path: RoutesFe.TAXONOMY.childPath(),
  component: TaxonomyComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
export const DATA_CATEGORIES_ROUTE = {
  path: RoutesFe.DATA_CATEGORIES.childPath(),
  component: DataCategoriesComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
export const NOTIFICATION_ROUTE = {
  path: RoutesFe.NOTIFICATION.childPath(),
  component: NotificationComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
export const DETAILS_ROUTE = {
  path: RoutesFe.DETAILS.childPath(),
  component: DetailsComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
export const NETWORK_ROUTE = {
  path: RoutesFe.NETWORK.childPath(),
  component: NetworkPortalComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
export const SM_INBOX_ROUTE = {
  path: RoutesFe.SM_INBOX.childPath(),
  component: SMInboxComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
export const REQUESTS_ROUTE = {
  path: RoutesFe.REQUESTS.childPath(),
  component: RequestsComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
export const DRAFTS_ROUTE = {
  path: RoutesFe.DRAFTS.childPath(),
  component: RequestDraftComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}

export const DO_INBOX_ROUTE = {
  path: RoutesFe.DO_INBOX.childPath(),
  component: DOInboxComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe],
  canDeactivate: [SaveAnswersGuard]
}
export const DO_REQUESTS_ROUTE = {
  path: RoutesFe.DO_REQUESTS.childPath(),
  component: DORequestsComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe],
  canDeactivate: [SaveAnswersGuard]
}

export const CLIENTS_ROUTE = {
  path: RoutesFe.CLIENTS.childPath(),
  component: ClientsComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
export const ADMINS_ROUTE = {
  path: RoutesFe.ADMINS.childPath(),
  component: CustomerSuccessComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
export const EMAIL_ROUTE = {
  path: RoutesFe.EMAIL.childPath(),
  component: EmailTableComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}
export const DATA_HUB_ROUTE = {
  path: RoutesFe.DATA_HUB.childPath(),
  component: DataHubComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}

export const UNIT_SYSTEMS_ROUTE = {
  path: RoutesFe.UNIT_SYSTEMS.childPath(),
  component: UnitSystemsComponent,
  canActivate: [AuthGuardFe, PasswordRotationGuardFe, CacheStateGuardFe]
}

export const OTHERS_ROUTE = { path: '**', redirectTo: RoutesFe.HOME.fullPath(), pathMatch: 'full' as PathMatch }
type PathMatch = 'full' | 'prefix' | undefined

const routes: Routes = [
  {
    path: RoutesFe.LAYOUT_PATH_OPEN,
    component: OpenLayoutComponent,
    children: [
      MANAGER_REGISTRATION_ROUTE,
      PARTNER_REGISTRATION_ROUTE,
      CONTACT_REGISTRATION_ROUTE,
      RESET_PASS_ROUTE,
      LOGIN_ROUTE,
      FORGOT_PASS_ROUTE,
      RESET_FORGOT_PASS_ROUTE,
      VERIFY_SHARED_DASHBOARD_ROUTE
    ]
  },
  {
    path: RoutesFe.LAYOUT_PATH_PRE_WS,
    component: PreWorkspaceLayoutComponent,
    children: [WORKSPACES_ROUTE, LOADING_ROUTE, ROTATE_PASS_ROUTE]
  },
  {
    path: RoutesFe.LAYOUT_PATH_WORKSPACE,
    component: WorkspaceLayoutComponent,
    children: [
      HOME_ROUTE,
      PROJECTS_ROUTE,
      DASHBOARD_ROUTE,
      // USER_KPI_ROUTE,
      INSIGHTS_HUB_ROUTE,
      FILES_ROUTE,
      DATA_ROUTE,
      EMISSION_ROUTE,
      ORGANIZATION_ROUTE,
      TAXONOMY_ROUTE,
      DATA_CATEGORIES_ROUTE,
      UNIT_SYSTEMS_ROUTE,
      NOTIFICATION_ROUTE,
      EMAIL_ROUTE,
      DETAILS_ROUTE,
      NETWORK_ROUTE,
      SM_INBOX_ROUTE,
      REQUESTS_ROUTE,
      DRAFTS_ROUTE,
      DO_INBOX_ROUTE,
      DO_REQUESTS_ROUTE,
      CLIENTS_ROUTE,
      ADMINS_ROUTE,
      PROFILE_ROUTE,
      COPMANY_SETTING_ROUTE,
      VIEW_TEMPLATE_ROUTE,
      DATA_HUB_ROUTE,
      REFERENCE_WIDGETS_COMPONENT
    ]
  },
  {
    path: RoutesFe.LAYOUT_PATH_VISITOR,
    component: WorkspaceLayoutComponent,
    children: [VISITOR_SHARED_DASHBOARD_ROUTE]
  },
  {
    path: RoutesFe.LAYOUT_PATH_VISITOR,
    component: PreWorkspaceLayoutComponent,
    children: [VISITOR_LOADING_ROUTE]
  },
  OTHERS_ROUTE
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { AbstractActivityFe } from '../../timeline/AbstractActivityFe'
import { AcceptSubmissionActivityFe } from '../../timeline/AcceptSubmissionActivityFe'
import { ActivityTypeFe } from '../../timeline/ActivityTypeFe'
import { AnswerAttachmentFe } from '../../timeline/answer/AnswerAttachmentFe'
import { AnswerDataTableFe } from '../../timeline/answer/AnswerDataTableFe'
import { AnswerDateFe } from '../../timeline/answer/AnswerDateFe'
import { AnswerMultipleChoiceFe } from '../../timeline/answer/AnswerMultipleChoiceFe'
import { AnswerNumberFe } from '../../timeline/answer/AnswerNumberFe'
import { AnswerSingleChoiceFe } from '../../timeline/answer/AnswerSingleChoiceFe'
import { AnswerTextFe } from '../../timeline/answer/AnswerTextFe'
import { CreateRequestActivityFe } from '../../timeline/CreateRequestActivityFe'
import { RejectSubmissionActivityFe } from '../../timeline/RejectSubmissionActivityFe'
import { SubmitAnswersActivityFe } from '../../timeline/SubmitAnswersActivityFe'
import { QuestionAttachmentFe } from './QuestionAttachmentFe'
import { QuestionDataTableFe } from './QuestionDataTableFe'
import { QuestionDateFe } from './QuestionDateFe'
import { QuestionFe } from './QuestionFe'
import { QuestionMultipleChoiceFe } from './QuestionMultipleChoiceFe'
import { QuestionNumberFe } from './QuestionNumberFe'
import { QuestionSingleChoiceFe } from './QuestionSingleChoiceFe'
import { QuestionTextFe } from './QuestionTextFe'
import { QuestionTypeConversion } from './QuestionTypeConversionFe'
import { SectionFe } from './SectionFe'
import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe'
import { QuestionEmissionFactorFe } from './QuestionEmissionFactorFe'
import { AnswerEmissionFactorFe } from '../../timeline/answer/AnswerEmissionFactorFe'
import { AnswerFe } from '../../timeline/answer/AnswerFe'

export class QUESTIONNAIRE_STATUS {
  public static CREATED: string = 'CREATED'
  public static DRAFT: string = 'DRAFT'
  public static SUBMITTED: string = 'SUBMITTED'
  public static APPROVED: string = 'APPROVED'
  public static REJECTED: string = 'REJECTED'
}

export class QuestionnaireFe {
  sections: SectionFe[]
  autoConnect: boolean
  requestId: string
  status: string = QUESTIONNAIRE_STATUS.CREATED

  relatedActivity: SubmitAnswersActivityFe | null

  constructor(sections: SectionFe[], autoConnect: boolean, requestId: string) {
    this.sections = sections
    this.autoConnect = autoConnect
    this.requestId = requestId
  }

  public static fromTransfer(transfer: any, taxonomyInfo?: TaxonomyInfoFe): QuestionnaireFe {
    let questions = transfer.sections.map((section) => SectionFe.fromTransfer(section, taxonomyInfo))
    let q = new QuestionnaireFe(questions, transfer.autoConnect, transfer.requestId)
    return q
  }

  adjustQuestionnaire(timelineItems: AbstractActivityFe[]) {
    let activities = timelineItems.slice().reverse()
    this.setStatus(activities)
    this.setRelatedActivity(activities)
    if (this.relatedActivity) this.setAnswers(this.relatedActivity)
  }

  setStatus(activities: AbstractActivityFe[]) {
    const activity = activities.find(
      (a) =>
        a.type == ActivityTypeFe.ACCEPT_SUBMISSION ||
        a.type == ActivityTypeFe.REJECT_SUBMISSION ||
        a.type == ActivityTypeFe.SUBMIT_ANSWERS ||
        a.type == ActivityTypeFe.CREATE_REQUEST
    )

    if (activity instanceof CreateRequestActivityFe) {
      this.status = QUESTIONNAIRE_STATUS.CREATED
    } else if (activity instanceof AcceptSubmissionActivityFe) {
      this.status = QUESTIONNAIRE_STATUS.APPROVED
    } else if (activity instanceof RejectSubmissionActivityFe) {
      this.status = QUESTIONNAIRE_STATUS.REJECTED
    } else if (activity instanceof SubmitAnswersActivityFe) {
      this.status = QUESTIONNAIRE_STATUS.SUBMITTED
    }
  }

  setRelatedActivity(activities: AbstractActivityFe[]) {
    let latestSubmission = activities.find((a) => a.type == ActivityTypeFe.SUBMIT_ANSWERS)
    if (latestSubmission instanceof SubmitAnswersActivityFe) {
      this.relatedActivity = latestSubmission
    } else {
      this.relatedActivity = null
    }
  }

  setAnswers(activity: SubmitAnswersActivityFe) {
    this.sections.forEach((section) => {
      section.questions.forEach((q) => (q.answer = null))
      activity.answers.forEach((a) => {
        let q: QuestionFe = this.getQuestionFromAnswer(a)
        if (q) q.answer = a
        if (a instanceof AnswerNumberFe && q instanceof QuestionNumberFe) {
          a.unit = a.unit ? a.unit : q.unit
        }
      })
    })
  }

  setNewAnswers(activity: SubmitAnswersActivityFe, mgmtCompanyId: string) {
    activity.answers.forEach((a) => {
      let q: QuestionFe = this.getQuestionFromAnswer(a)
      if (q) {
        a.sectionId = q.sectionId
        q.newAnswer.comments = a.comments
        if (q instanceof QuestionAttachmentFe && a instanceof AnswerAttachmentFe) {
          q.newAnswer.managementCompanyId = mgmtCompanyId
          q.newAnswer.attachmentLabel = a.attachmentLabel
          q.newAnswer.attachmentStorageId = a.attachmentStorageId
        } else if (q instanceof QuestionDataTableFe && a instanceof AnswerDataTableFe) {
          q.newAnswer.managementCompanyId = mgmtCompanyId
          q.newAnswer.dataStorageId = a.dataStorageId
        } else if (q instanceof QuestionDateFe && a instanceof AnswerDateFe) {
          q.newAnswer.dateFrom = a.dateFrom
          q.newAnswer.dateFromInput = a.dateFrom ? a.dateFrom.toISOString().split('T')[0] : ''
          q.newAnswer.dateTo = a.dateTo ? new Date(a.dateTo) : a.dateTo
          q.newAnswer.dateToInput = a.dateTo ? a.dateTo.toISOString().split('T')[0] : ''
        } else if (q instanceof QuestionMultipleChoiceFe && a instanceof AnswerMultipleChoiceFe) {
          q.newAnswer.selectedOptionIds = a.selectedOptionIds
        } else if (q instanceof QuestionNumberFe && a instanceof AnswerNumberFe) {
          q.newAnswer.value = a.value
          q.newAnswer.unit = a.unit ? a.unit : q.unit
        } else if (q instanceof QuestionSingleChoiceFe && a instanceof AnswerSingleChoiceFe) {
          q.newAnswer.selectedOptionId = a.selectedOptionId
        } else if (q instanceof QuestionTextFe && a instanceof AnswerTextFe) {
          q.newAnswer.text = a.text
        } else if (q instanceof QuestionEmissionFactorFe && a instanceof AnswerEmissionFactorFe) {
          q.newAnswer.selectedEmissionFactor = a.selectedEmissionFactor
        }
      }
    })
  }

  getQuestions() {
    let questions = this.sections.reduce((result, current) => result.concat(current.questions), [])
    return questions
  }

  getQuestionFromAnswer(a: AnswerFe) {
    let q: QuestionFe
    if (a.sectionId) {
      q = this.getQuestions().find((q) => q.id == a.questionId && q.sectionId == a.sectionId)
    }
    if (!q) {
      q = this.getQuestions().find((q) => q.id == a.questionId)
      //inorder to keep answers of old request that were sent before sections were inroduced intact
    }
    return q
  }

  setParentIds() {
    this.sections.forEach((section) => section.setParentIds())
  }

  findQuestionSection(question: QuestionFe): SectionFe {
    let section = this.sections.find((section) => section.id == question.sectionId)
    return section
  }

  ifAnswerIsStringForFollowup(question: QuestionFe) {
    let section: SectionFe = this.findQuestionSection(question)
    return section.ifAnswerIsStringForFollowup(question)
  }

  /**
   * Generates reference numbers for all questions in the questionnaire.
   * @returns A map of QuestionFe to their reference numbers.
   */
  getQuestionReferenceNumbers(): Map<string, string> {
    const questionMap = new Map<string, string>()

    this.sections.forEach((section, sectionIndex) => {
      const sectionNumber = sectionIndex + 1
      let questionIndex = 1

      // Get root questions of the section
      const rootQuestions = section.getQuestionsWithoutParent()

      rootQuestions.forEach((question) => {
        // Recursively assign reference numbers to root questions and their children
        this.assignQuestionNumber(section, question, `${questionIndex}`, questionMap, sectionNumber)
        questionIndex++
      })
    })

    return questionMap
  }

  /**
   * Recursively assigns reference numbers to questions and their follow-ups.
   * @param section The section the question belongs to.
   * @param question The current question to assign a reference number.
   * @param questionNumber The reference number to assign (e.g., "1.1").
   * @param questionMap The map of questions to reference numbers.
   * @param sectionNumber The current section number.
   */
  private assignQuestionNumber(
    section: SectionFe,
    question: QuestionFe,
    questionNumber: string,
    questionMap: Map<string, string>,
    sectionNumber: number
  ): void {
    // Assign the formatted reference number to the current question
    questionMap.set(question.id, `${sectionNumber} | ${questionNumber}`)

    // Get the follow-up questions for this question
    const childQuestions = section.getChildrenOfParent(question)
    // Recursively assign reference numbers to child questions
    childQuestions.forEach((childQuestion, childIndex) => {
      const childQuestionNumber = `${questionNumber}.${childIndex + 1}`
      this.assignQuestionNumber(section, childQuestion, childQuestionNumber, questionMap, sectionNumber)
    })
  }

  toJSON(): any {
    const { relatedActivity, status, ...data } = this
    return data
  }
}

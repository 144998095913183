import { QuestionFe } from './QuestionFe'
import { QUESTION_TYPE } from './QuestionTypeFe'
import { BooleanStatementFe } from './condition/BooleanStatementFe'
import { AnswerNumber_AddFe } from '../../timeline/add/answer/AnswerNumber_AddFe'
import { IdUtil } from 'src/app/utils/IdUtil'
import { AnswerNumberFe } from '../../timeline/answer/AnswerNumberFe'
import { BooleanStatementBuilderFe } from './condition/BooleanStatementBuilderFe'
import { FormControl, Validators } from '@angular/forms'
import { PredefinedNumberOptionFe } from './predefinedoption/PredefinedNumberOptionFe'

export class QuestionNumberFe extends QuestionFe {
  unit: string
  measurementKey: string
  askUnitFromDO: boolean = true
  predefinedOptions: PredefinedNumberOptionFe[]
  customValuesAllowedWhenPredefinedOptionsSet: boolean = true

  newAnswer: AnswerNumber_AddFe = new AnswerNumber_AddFe(IdUtil.next(), this.sectionId, this.id, null, null, null)
  answer: AnswerNumberFe
  unitControl = new FormControl(null, Validators.required)

  tempPredefinedOption: PredefinedNumberOptionFe = new PredefinedNumberOptionFe()

  constructor(
    id: string,
    sectionId: string,
    ordinal: number | null,
    followUpCondition: BooleanStatementFe | null,
    question: string,
    desc: string | null,
    comments: boolean,
    required: boolean,
    mappedToColKeys: string[] | null,
    unit: string,
    measurementKey: string,
    askUnitFromDO: boolean,
    predefinedOptions: PredefinedNumberOptionFe[],
    customValuesAllowedWhenPredefinedOptionsSet: boolean,
    csrdDatapointId: string | undefined,
    referencedParagraph: string | undefined,
    referenceApplicationRequirement: string | undefined,
    referencedParagraphInformation: string | undefined,
    applicationRequirements: string[] | undefined,
    supplementaryParagraph: string | undefined,
    supplementary: string | undefined,
    applicationRequirementFootnotes: string | undefined,
    drFootNote: string | undefined,
    csrdQuestionId?: string,
    csrdDisclosureRequirementId?: string,
    isRelevant?: boolean,
    parentQuestionId?: string
  ) {
    super(
      id,
      sectionId,
      QUESTION_TYPE.NUMBER,
      ordinal,
      followUpCondition,
      question,
      desc,
      comments,
      required,
      mappedToColKeys,
      csrdDatapointId,
      referencedParagraph,
      referenceApplicationRequirement,
      referencedParagraphInformation,
      applicationRequirements,
      supplementaryParagraph,
      supplementary,
      applicationRequirementFootnotes,
      drFootNote,
      csrdQuestionId,
      csrdDisclosureRequirementId,
      isRelevant,
      parentQuestionId
    )
    this.unit = unit
    this.measurementKey = measurementKey
    this.askUnitFromDO = askUnitFromDO
    this.newAnswer.unit = this.newAnswer.unit ? this.newAnswer.unit : this.unit
    this.predefinedOptions = predefinedOptions
    this.customValuesAllowedWhenPredefinedOptionsSet = customValuesAllowedWhenPredefinedOptionsSet
  }

  public static fromTransfer(transfer: any, sectionId: string): QuestionNumberFe {
    transfer.followUpCondition = transfer.followUpCondition
      ? BooleanStatementBuilderFe.fromFormulaString(transfer.followUpCondition)
      : null
    let item = this.setQuestion(transfer, sectionId)
    return item
  }

  public static setQuestion(transfer: any, sectionId?): QuestionNumberFe {
    let item = new QuestionNumberFe(
      transfer.id,
      sectionId ? sectionId : transfer.sectionId,
      transfer.ordinal,
      transfer.followUpCondition,
      transfer.question,
      transfer.desc,
      transfer.comments,
      transfer.required,
      transfer.mappedToColKeys,
      transfer.unit,
      transfer.measurementKey,
      transfer.askUnitFromDO,
      transfer.predefinedOptions
        ? transfer.predefinedOptions.map((item) => PredefinedNumberOptionFe.fromJsonObj(item))
        : [],
      transfer.customValuesAllowedWhenPredefinedOptionsSet ?? true,
      transfer.csrdDatapointId,
      transfer.referencedParagraph,
      transfer.referenceApplicationRequirement,
      transfer.referencedParagraphInformation,
      transfer.applicationRequirements,
      transfer.supplementaryParagraph,
      transfer.supplementary,
      transfer.applicationRequirementFootnotes,
      transfer.drFootNote,
      transfer.csrdQuestionId,
      transfer.csrdDisclosureRequirementId,
      transfer.isRelevant,
      transfer.parentQuestionId
    )
    item.isFollowUpQues = !!transfer.followUpCondition
    return item
  }

  isUnitRequiredFromSM() {
    return this.isMapped ? !this.askUnitFromDO : false
  }

  getSortedPredfinedNumberOptions(): PredefinedNumberOptionFe[] {
    return this.predefinedOptions
      .filter((item) => item)
      .sort((a: PredefinedNumberOptionFe, b: PredefinedNumberOptionFe) => {
        if (a.unit && b.unit) {
          const cmp = a.unit?.localeCompare(b.unit)
          if (cmp == 0) {
            return a.value - b.value
          }
          return cmp
        } else if (a.value && b.value) {
          return a.value - b.value
        }
        return 0
      })
  }

  isUnitOnly(): boolean {
    return (
      this.predefinedOptions &&
      this.predefinedOptions.length === 1 &&
      this.predefinedOptions[0].value == null &&
      this.predefinedOptions[0].value !== 0
    )
  }
}

import {
  Component,
  OnInit,
  Input,
  Output,
  HostListener,
  ChangeDetectorRef,
  EventEmitter,
  ViewChild,
  TemplateRef,
  ElementRef
} from '@angular/core'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { DateUtil } from 'src/app/utils/DateUtil'
import { QuestionFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionFe'
import { Subject, Subscription } from 'rxjs'
import { QuestionDataTableFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionDataTableFe'
import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { RequestServiceFe } from 'src/app/services/RequestServiceFe'
import { SectionFe } from 'src/app/model/data-suppliers/request/questionnaire/SectionFe'
import _ from 'lodash'
import { QuestionnaireFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionnaireFe'
import { QuestionDateFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionDateFe'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { CsrdDefintionService } from '../../projects/csrd/CsrdDefintionService'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { CsrdQuestionnaireFe } from 'src/app/model/data-suppliers/request/csrd/CSRDQuestionnaireFe'
import { NoUnit } from '../../unit-systems/unit-selector/unit-selector.component'
import { QuestionNumberFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionNumberFe'
import { PredefinedNumberOptionFe } from 'src/app/model/data-suppliers/request/questionnaire/predefinedoption/PredefinedNumberOptionFe'
import { PredefinedDateOptionFe } from 'src/app/model/data-suppliers/request/questionnaire/predefinedoption/PredefinedDateOptionFe'

@Component({
  selector: 'questionnaire-preview',
  templateUrl: './questionnaire-preview.component.html',
  styleUrls: ['./questionaire-creator-full-page.component.scss', '../data-suppliers.component.scss']
})
export class QuestionnairePreviewComponent extends AbstractLanguageComponent implements OnInit {
  @ViewChild('scrollTop') scrollTop: ElementRef

  loadingInfo = false
  loadingInProgress = false

  @Input() showHeader: boolean = true
  @Input() requestTitle: string
  @Input() questionnaire: QuestionnaireFe
  @Input() allExpanded: boolean
  @Input() searchText: string
  @Input() expandCollapseEvent: EventEmitter<boolean>
  @Input() editMapping: boolean

  @Output() closeEvent: Subject<boolean> = new Subject()
  @Output() accordionStateChange = new EventEmitter<boolean>()
  @Output() editMappingChange = new EventEmitter<boolean>()

  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  mappingEdited = false
  selectedMainQuestion: QuestionFe
  taxonomyInfo: TaxonomyInfoFe
  toConfirmUpdate = false
  sections: SectionFe[]
  selectedSection: SectionFe | null = null
  shown: { [key: string]: boolean } = {}
  detailExpanded: { [key: string]: boolean } = {}
  subtreeExpanded: { [key: string]: boolean } = {}
  subscription: Subscription = new Subscription()
  private accordionSubscription: Subscription = new Subscription()
  questionsRefNumber: Map<string, string> = new Map()
  isCSRDQuestionnaire: boolean = false

  subscriptionDr: Subscription
  activeDr: any
  isCsrdDatapointTabOpen = false
  isEditMode: boolean[] = []
  isFormView: boolean = false

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    public stateService: StateServiceFe,
    private responsive: ResponsiveService,
    public requestService: RequestServiceFe,
    private displayService: DisplayServiceFe,
    languageService: LanguageService,
    public csrdDefintionService: CsrdDefintionService,
    private cd: ChangeDetectorRef
  ) {
    super(languageService)
    stateService.workspaceSubject.subscribe((activeCompany) => {
      this.loadCompaniesTaxonomy()
    })
    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
  }

  async ngOnInit(): Promise<void> {
    this.renderNewData()
    this.loadCompaniesTaxonomy()
    this.expandCollapseEvent.subscribe((expanded: boolean) => {
      this.expandCollapseAll(expanded)
    })
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
    this.accordionSubscription.unsubscribe()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.scrollToTop()
    })
  }

  scrollToTop() {
    if (this.selectedMainQuestion) {
      setTimeout(() => {
        this.scrollTop.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
      })
    }
  }

  async renderNewData() {
    this.loadingInfo = true
    this.isCSRDQuestionnaire = this.questionnaire instanceof CsrdQuestionnaireFe ? true : false
    this.questionsRefNumber = this.questionnaire.getQuestionReferenceNumbers()
    this.questionnaire.setParentIds()
    this.sections = this.questionnaire.sections //_.cloneDeep(this.questionnaire.sections)
    this.sections.forEach((section) => {
      section.questions.forEach((q) => {
        if (q instanceof QuestionDataTableFe) {
          q.setRequestDataGridService()
        }
        if (q.mappedToColKeys && section.entity) {
          q.setMappedToCols(section.entity)
        }
        if (q instanceof QuestionDateFe && q.mappedToColKeysRange) {
          q.setMappedToColsRange(section.entity)
        }
        q.confirmMapping()
      })
      section.editTaxonomy = false
    })
    this.loadingInfo = false
  }

  async loadCompaniesTaxonomy() {
    let { depTaxonomy, newTaxonomy } = await this.stateService.getTaxonomyInfos()
    this.taxonomyInfo = depTaxonomy
  }

  close() {
    this.closeEvent.next(true)
  }

  // Questionnaire functionality
  showMenu(event: any) {
    let id = event.currentTarget.id
    let idNumber = id.split('-')[1]
    let menuBtn = document.getElementById(id)
    let menu = menuBtn.nextElementSibling
    let chevron = menuBtn.children[2]
    menu.classList.toggle('show')
    if (chevron.classList.contains('la-angle-down')) {
      chevron.classList.remove('la-angle-down')
      chevron.classList.add('la-angle-up')
    } else {
      chevron.classList.remove('la-angle-up')
      chevron.classList.add('la-angle-down')
    }
  }

  dateToString(date: any) {
    date = new Date(date)
    return DateUtil.toString3(date)
  }

  closeModal() {
    this.modalService.hide(this.modalRef.id)
    document.body.classList.remove('modal-open')
  }

  // async updateMapping() {
  //   let questionnaire = new QuestionnaireFe(this.sections, this.questionnaire.autoConnect, this.request.id)
  //   let update = new RequestGroup_UpdateFe(this.request.requestGroupId, questionnaire)
  //   await this.stateService.updateRequestGroupQuestionnaire(update)
  // }

  selectMainQuestion(mainQuestion: QuestionFe) {
    this.selectedSection = this.questionnaire.findQuestionSection(mainQuestion)
    this.selectedMainQuestion = mainQuestion

    // Expand the selected question to show its children
    this.shown[mainQuestion.id] = true
    this.detailExpanded[mainQuestion.id] = true
    this.subtreeExpanded[mainQuestion.id] = true

    this.collapseAndHideSiblings(mainQuestion)
    this.showChildrenCollapsed(mainQuestion)
    setTimeout(() => {
      this.scrollToTop()
    }, 100)
  }

  backToMainQuestions() {
    const previousQuestionId = this.selectedMainQuestion?.id

    this.selectedSection = null
    this.selectedMainQuestion = null

    setTimeout(() => {
      const element = document.getElementById(previousQuestionId)
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }, 100)
  }

  findQuestionById(id: string): QuestionFe | null {
    for (const section of this.sections) {
      for (const question of section.questions) {
        if (question.id === id) {
          return question
        }
        const followUp = this.findInFollowUps(question, id)
        if (followUp) {
          return followUp
        }
      }
    }
    return null
  }

  findInFollowUps(question: QuestionFe, id: string): QuestionFe | null {
    if (question.id === id) {
      return question
    }
    const children = this.selectedSection.getChildrenOfParent(question)
    for (const followUp of children) {
      const result = this.findInFollowUps(followUp, id)
      if (result) {
        return result
      }
    }
    return null
  }

  toggleDetailExpansion(question: QuestionFe) {
    this.detailExpanded[question.id] = !this.detailExpanded[question.id]
  }

  toggleFollowUpQuestions(question: QuestionFe) {
    if (this.subtreeExpanded[question.id]) {
      this.hideFollowupQuestions(question)
      // After hiding, scroll to the question
      setTimeout(() => {
        const element = document.getElementById(question.id)
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          })
        }
      }, 0)
    } else {
      this.showFollowupQuestions(question)
    }
  }

  hideFollowupQuestions(question: QuestionFe) {
    this.subtreeExpanded[question.id] = false
    this.collapseAndHideChildrenSbtrees(question)
    this.showSiblingsCollapsed(question)
  }

  showFollowupQuestions(question: QuestionFe) {
    this.subtreeExpanded[question.id] = true
    this.collapseAndHideSiblings(question)
    this.showChildrenCollapsed(question)
  }

  getAllFollowUpQuestions(question: QuestionFe): QuestionFe[] {
    let allQuestions: QuestionFe[] = []
    const directChildren = this.selectedSection.getChildrenOfParent(question)

    directChildren.forEach((child) => {
      allQuestions.push(child)
      allQuestions = allQuestions.concat(this.getAllFollowUpQuestions(child))
    })

    return allQuestions
  }

  collapseAndHideSiblings(question: QuestionFe) {
    const siblings = this.selectedSection.getSiblings(question)
    siblings.forEach((sibling) => {
      this.collapseAndHideSubtree(sibling)
    })
  }

  showSiblingsCollapsed(question: QuestionFe) {
    const siblings = this.selectedSection.getSiblings(question)
    siblings.forEach((sibling) => {
      this.shown[sibling.id] = true
      this.subtreeExpanded[sibling.id] = false
    })
  }

  collapseAndHideChildrenSbtrees(question: QuestionFe) {
    const children = this.selectedSection.getChildrenOfParent(question)
    children.forEach((child) => {
      this.collapseAndHideSubtree(child)
    })
  }

  showChildrenCollapsed(question: QuestionFe) {
    const children = this.selectedSection.getChildrenOfParent(question)
    children.forEach((child) => {
      this.shown[child.id] = true
      this.subtreeExpanded[child.id] = false
    })
  }

  collapseAndHideSubtree(question: QuestionFe) {
    const children = this.selectedSection.getChildrenOfParent(question)
    children.forEach((child) => {
      this.shown[child.id] = false
      this.subtreeExpanded[child.id] = false
      this.collapseAndHideSubtree(child)
    })
    this.shown[question.id] = false
    this.subtreeExpanded[question.id] = false
  }

  hasFollowUpQuestions(question: QuestionFe): boolean {
    let section = this.questionnaire.findQuestionSection(question)
    return section.getChildrenOfParent(question).length > 0
  }

  getQuestionLevel(question: QuestionFe): number {
    let level = 0
    let currentQuestion = question

    while (currentQuestion.parentQuestionId) {
      level++
      currentQuestion = this.findQuestionById(currentQuestion.parentQuestionId)
    }

    return level
  }

  getIndentation(level: number): string {
    const baseIndent = 20
    const maxLevel = 5
    return `${Math.min(level, maxLevel) * baseIndent}px`
  }

  expandCollapseAll(expanded: boolean) {
    this.allExpanded = expanded
    this.sections.forEach((section, index) => {
      const accordionButton = document.getElementById(`section${index}Preview`)
      const accordionContent = document.getElementById(`sectionAccordion${index}Preview`)
      if (accordionButton && accordionContent) {
        if (this.allExpanded) {
          accordionButton.classList.remove('collapsed')
          accordionButton.setAttribute('aria-expanded', 'true')
          accordionContent.classList.add('show')
        } else {
          accordionButton.classList.add('collapsed')
          accordionButton.setAttribute('aria-expanded', 'false')
          accordionContent.classList.remove('show')
        }
      }
    })

    this.accordionStateChange.emit(this.allExpanded)
    this.cd.detectChanges()
  }

  getMainQuestions(section: any): QuestionFe[] {
    if (!this.searchText) {
      return section.getQuestionsWithoutParent()
    }

    const lowerCaseSearchText = this.searchText.toLowerCase().trim()

    const questionsToFilter = section.getQuestionsWithoutParent()

    return questionsToFilter.filter(
      (question) =>
        question.question.toLowerCase().includes(lowerCaseSearchText) ||
        (question.titleNumber && question.titleNumber.toLowerCase().includes(lowerCaseSearchText))
    )
  }

  saveTitle(index: number) {
    this.isEditMode[index] = false
  }

  cancelEditTitle(index: number) {
    this.isEditMode[index] = false
  }
  updateInputWidth(event: any) {
    const input = event.target
    input.style.width = `${input.value.length + 1}ch`
  }

  stopImmediatePropagation(event: Event) {
    event.stopPropagation()
    event.preventDefault()
  }

  openCSRDQuestionInfo(question: QuestionFe) {
    const csrdDatapoint = this.csrdDefintionService.getCsrdDatapointById(question.csrdDatapointId)
    const csrdDatapointSettings = this.csrdDefintionService.getCsrdDatapointSettings(question.csrdDatapointId)
    this.displayService.openCSRDQuestionTab(question, csrdDatapoint, csrdDatapointSettings)
  }

  setFormView() {
    this.isFormView = true
  }

  setTableView() {
    this.isFormView = false
  }

  toggleMapping() {
    this.editMapping = !this.editMapping
    this.editMappingChange.emit(this.editMapping)
  }

  containsPredefinedOptions(question: QuestionFe): boolean {
    return (question as any).predefinedOptions?.length > 0
  }

  getUnit(option) {
    return option.unit || NoUnit.SYMBOL
  }

  getSortedPredfinedNumberOptions(question: QuestionFe): PredefinedNumberOptionFe[] {
    const questionNumber = question as QuestionNumberFe
    return questionNumber.getSortedPredfinedNumberOptions()
  }

  getSortedPredfinedDateOptions(question: QuestionFe): PredefinedDateOptionFe[] {
    const questionDate = question as QuestionDateFe
    return questionDate.getSortedPredfinedDateOptions()
  }

  openEfDetailTab(selectedEF) {
    this.displayService.openEfDetailsTab(selectedEF)
  }

  isDatapointMaterial(csrdDatapointId: string): boolean {
    const datapoint = this.csrdDefintionService.getCsrdDatapointSettings(csrdDatapointId)
    // if datapoint is undefined or null, it will be considered as material, same as on DO side relevance
    if (datapoint === undefined || datapoint === null) {
      return true
    }

    return datapoint.isMaterial
  }
}

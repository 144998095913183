import { Component, OnInit, TemplateRef, OnDestroy, ViewChild } from '@angular/core'
import { BsModalService } from 'ngx-bootstrap/modal'
import { Subscription } from 'rxjs'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { LoginServiceFe } from 'src/app/services/LoginServiceFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { UserServiceFe } from 'src/app/services/UserServiceFe'
import { VisitorServiceFe } from 'src/app/services/VisitorServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends AbstractLanguageComponent implements OnInit, OnDestroy {
  @ViewChild('logoutModal') logoutModal: TemplateRef<any>
  copyrightYearRange: string
  isLoading: boolean = false
  shouldDisableLogout: boolean = false
  answerAutoSaveSubscription: Subscription
  didUserClickLogout: boolean = false

  constructor(
    public stateService: StateServiceFe,
    private loginService: LoginServiceFe,
    private visitorService: VisitorServiceFe,
    private userService: UserServiceFe,
    languageService: LanguageService,
    private modalService: BsModalService
  ) {
    super(languageService)
  }

  ngOnInit(): void {
    const currentYear = new Date().getFullYear()

    this.copyrightYearRange = `2020-${currentYear}`
    this.didUserClickLogout = false

    this.answerAutoSaveSubscription = this.stateService.answersAutoSaveCompleteSubject.subscribe((isComplete) => {
      if (isComplete && this.shouldDisableLogout && this.didUserClickLogout) {
        this.shouldDisableLogout = false
        this.openModal(this.logoutModal, 'modal-md')

        return
      }
      this.shouldDisableLogout = !isComplete
    })
  }

  ngOnDestroy(): void {
    this.answerAutoSaveSubscription.unsubscribe()
  }

  async logout() {
    this.isLoading = true
    if (this.isUserAVisitor()) {
      await this.visitorService.logout()
    } else {
      await this.stateService.updateRecentlyOpenedItems()
      await this.loginService.logout()
    }
    this.isLoading = false
    this.close()
  }

  public isUserAVisitor() {
    return this.userService.isUserAVisitor()
  }

  openModal(templateRef: TemplateRef<any>, size: string = '') {
    this.didUserClickLogout = true
    if (this.shouldDisableLogout) {
      this.userService.userWantsToLogoutSubject.next(true)

      return
    }

    this.modalService.show(templateRef, { class: size })
  }

  close() {
    this.modalService.hide()
    this.didUserClickLogout = false
  }
}

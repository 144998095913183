import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanDeactivate } from '@angular/router'
import { Observable } from 'rxjs'

export interface ISaveAnswersCanDeactivate {
  canDeactivate: (nextUrl: string) => Observable<boolean> | Promise<boolean> | boolean
}

@Injectable({
  providedIn: 'root'
})
export class SaveAnswersGuard implements CanDeactivate<ISaveAnswersCanDeactivate> {
  canDeactivate(
    component: ISaveAnswersCanDeactivate,
    next: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const nextUrl = next.url.join('/')

    return component.canDeactivate ? component.canDeactivate(nextUrl) : true
  }
}

import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { RequestGroupFe } from '../../../model/data-suppliers/request/RequestGroupFe'
import { AbstractActivityFe } from '../../../model/data-suppliers/timeline/AbstractActivityFe'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { DateUtil } from 'src/app/utils/DateUtil'
import { LoginServiceFe } from 'src/app/services/LoginServiceFe'
import { RejectSubmissionActivityFe } from 'src/app/model/data-suppliers/timeline/RejectSubmissionActivityFe'
import { AcceptSubmissionActivityFe } from 'src/app/model/data-suppliers/timeline/AcceptSubmissionActivityFe'
import { READ_STATUS, REQUEST_TASK_STATUS } from 'src/app/model/data-suppliers/request/RequestFe'
import { QUESTIONNAIRE_STATUS } from 'src/app/model/data-suppliers/request/questionnaire/QuestionnaireFe'
import { SubmitAnswersActivityFe } from 'src/app/model/data-suppliers/timeline/SubmitAnswersActivityFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { TaskFe } from 'src/app/model/data-suppliers/task/TaskFe'
import { AnswerComponent } from '../answer/answer.component'
import { Observable } from 'rxjs'
import { Router } from '@angular/router'
import { ISaveAnswersCanDeactivate } from 'src/app/routeGuard/save-answers.guard'

@Component({
  selector: 'app-do-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['../../common/data-suppliers.component.scss', './inbox.component.scss']
})
export class DOInboxComponent extends AbstractLanguageComponent implements OnInit, ISaveAnswersCanDeactivate {
  requestGroups: RequestGroupFe[] = []
  tasks: TaskFe[] = []
  detailsData: any
  loadingInfo = false
  toDoActivitiesGroupedByReq: { [key: string]: { activities: AbstractActivityFe[]; isRead: boolean } } = {}
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  isChatOpen = false
  isToDoListOpen = true
  isExpanded = false
  searchKeywordRg = ''
  latestAnswerActivity: SubmitAnswersActivityFe
  pageToolbar = [
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.info'),
        longLabel: this.locale('locale_key.general.toolbar.button.show_quick_tips'),
        tooltip: this.locale('locale_key.general.toolbar.button.show_quick_tips'),
        icon: 'la la-info fs-4',
        actionName: 'toggle_quick_tips',
        visible: () => true,
        disabled: false
      }
    ]
  ]

  selectedRequestGroup: RequestGroupFe
  selectedTask: TaskFe
  selectedActivity: AbstractActivityFe
  answerMode = false

  //FOR FILTERING
  searchKeyword: string = ''
  filteredTasks: TaskFe[] = []
  selectedStatus = null
  customMultipleFilters: { name: string; value: string; type: 'SM' | 'COMPANY' }[] = []
  filteredCompanies: { companyId: string; name: string }[] = []
  filteredSMs: { affId: string; name: string }[] = []
  showQuestionnaireIcon: boolean = true
  pendingNavigationUrl: string
  @ViewChild(AnswerComponent) answerComponent!: AnswerComponent

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    public stateService: StateServiceFe,
    private displayService: DisplayServiceFe,
    public loginService: LoginServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {
    super(languageService)

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    this.screenSize = responsive.currentScreenWidthSize
  }

  async ngOnInit(): Promise<void> {
    this.loadingInfo = true
    await this.renderNewData()

    this.stateService.requestTimelineItemsUpdatedSubject.subscribe((activity) => {
      let index = this.selectedTask.timelineItems.findIndex((act) => act.id == activity.id)
      this.selectedTask.timelineItems[index] = activity
    })
    this.displayService.closeTips()
    this.loadingInfo = false
  }

  handlePendingNavigationUrl() {
    if (!this.pendingNavigationUrl) return

    this.router.navigate([this.pendingNavigationUrl])
    this.pendingNavigationUrl = null
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.answerComponent) return true

    const isAutoSaveComplete = this.answerComponent.isQuestionnaireAutoSaveCompleted()
    const nextUrl = this.router.getCurrentNavigation()?.finalUrl?.toString()
    if (!isAutoSaveComplete) {
      this.pendingNavigationUrl = nextUrl

      return false
    }
    this.pendingNavigationUrl = null

    return true
  }

  async renderNewData(): Promise<void> {
    this.requestGroups = await this.stateService.getRequestGroups()
    this.tasks = await this.stateService.getTasks()
    await this.setFilterData()
  }

  setFilterData() {
    this.applyFilters()
    this.filterCompanies()
    this.filterSMs()
  }

  async adjustInboxData() {
    let allTaskActivities = []
    this.filteredTasks.forEach((task) => allTaskActivities.push(...task.timelineItems))
    this.toDoActivitiesGroupedByReq = {}
    let unreadActivities = []
    allTaskActivities.forEach((a) => {
      if (a.readStatus == READ_STATUS.UNREAD && a.submitterAffId != this.stateService.activeWorkspace.affiliationId) {
        unreadActivities.push(a)
      }
    })
    let toDoActivities: AbstractActivityFe[] = []
    allTaskActivities.forEach((a) => {
      let task = this.tasks.find((r) => r.id == a.requestId)
      if (task.status == REQUEST_TASK_STATUS.CREATED && unreadActivities.every((ua) => ua.id != a.id)) {
        toDoActivities.push(a)
      } else if (
        task.status == REQUEST_TASK_STATUS.IN_PROGRESS &&
        a instanceof RejectSubmissionActivityFe &&
        this.noSubmissionMade(a, task) &&
        unreadActivities.every((ua) => ua.id != a.id)
      ) {
        toDoActivities.push(a)
      }
    })
    let toDoListActivities = [...unreadActivities, ...toDoActivities]
    toDoListActivities.sort((a, b) => new Date(b.submissionDate).getTime() - new Date(a.submissionDate).getTime())
    toDoListActivities.forEach((a) => {
      if (this.toDoActivitiesGroupedByReq[a.requestId]) {
        this.toDoActivitiesGroupedByReq[a.requestId].activities.push(a)
      } else {
        this.toDoActivitiesGroupedByReq[a.requestId] = { activities: [a], isRead: a.readStatus == READ_STATUS.READ }
      }
    })
  }

  noSubmissionMade(a: RejectSubmissionActivityFe, task: TaskFe): boolean {
    let activity = task.timelineItems
      .slice()
      .reverse()
      .find((act) => act instanceof RejectSubmissionActivityFe || act instanceof SubmitAnswersActivityFe)
    return activity.id == a.id
  }

  isSubmitAnswer(activity: AbstractActivityFe) {
    return activity instanceof SubmitAnswersActivityFe
  }

  isAccept(activity: AbstractActivityFe) {
    return activity instanceof AcceptSubmissionActivityFe
  }

  isReject(activity: AbstractActivityFe) {
    return activity instanceof RejectSubmissionActivityFe
  }

  async updateDetailsData(requestGroup: RequestGroupFe, task: TaskFe = null) {
    this.detailsData = {
      requestGroup: requestGroup,
      task: task
    }
    this.displayService.updateDetailsData(this.detailsData)
    //TODO
    //this.displayService.updateQuickTipsData({ viewMode: `view_${this.viewNo}`, page: "requests" });
  }

  toggleTips() {
    this.displayService.toggleTips()
  }

  getToDoActivitiesGroup() {
    return Object.values(this.toDoActivitiesGroupedByReq)
  }

  getDoneRequestList() {
    let completedReq = this.filteredTasks.filter(
      (req) =>
        req.questionnaire.status == QUESTIONNAIRE_STATUS.SUBMITTED ||
        req.questionnaire.status == QUESTIONNAIRE_STATUS.APPROVED
    )
    let readRequests = completedReq.filter((req) => req.readStatus == READ_STATUS.READ)
    return readRequests.sort((a, b) =>
      DateUtil.getTimeDifference(this.getLastActivity(b)?.submissionDate, this.getLastActivity(a)?.submissionDate)
    )
  }

  selectDoneRequest(task: TaskFe) {
    this.selectedActivity = this.getLastActivity(task)
    this.selectedTask = task
    this.selectedRequestGroup = this.getRequestGroup(task.requestGroupId)
  }

  getLastActivity(task: TaskFe) {
    return task.timelineItems[task.timelineItems.length - 1]
  }

  async selectNewActivity(groupIndex: number) {
    this.selectedActivity = Object.values(this.toDoActivitiesGroupedByReq)[groupIndex].activities[0]
    let selectedReqId = Object.keys(this.toDoActivitiesGroupedByReq)[groupIndex]
    this.selectedRequestGroup = this.requestGroups.find(
      (rg) => rg.id == Object.values(this.toDoActivitiesGroupedByReq)[groupIndex].activities[0].requestGroupId
    )
    this.selectedTask = this.tasks.find((r) => r.id == selectedReqId)
    if (this.toDoActivitiesGroupedByReq[selectedReqId].activities[0].readStatus == READ_STATUS.UNREAD) {
      await this.stateService.markAllTaskActivitiesRead(this.selectedTask)
    }
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case 'toggle_quick_tips':
        this.toggleTips()
        break
    }
  }

  getRequestGroup(id: string) {
    return this.requestGroups.find((r) => r.id == id)
  }

  openMessageModal() {
    this.isChatOpen = !this.isChatOpen
  }

  getRequestTitle(task: TaskFe): string {
    let rg = this.getRequestGroup(task.requestGroupId)
    return rg.title
  }

  // NEW FUNCTIONS

  async selectTodoActivity(groupIndex: number) {
    this.adjustInboxData()
    let selectedReqId = Object.keys(this.toDoActivitiesGroupedByReq)[groupIndex]
    this.selectedRequestGroup = this.requestGroups.find(
      (rg) => rg.id == Object.values(this.toDoActivitiesGroupedByReq)[groupIndex].activities[0].requestGroupId
    )
    this.selectedTask = this.tasks.find((r) => r.id == selectedReqId)
    if (this.toDoActivitiesGroupedByReq[selectedReqId].activities[0].readStatus == READ_STATUS.UNREAD) {
      await this.stateService.markAllTaskActivitiesRead(this.selectedTask)
    }
  }

  openModal(template: TemplateRef<any>, size: string = 'modal-md') {
    this.modalRef = this.modalService.show(template, { class: size, backdrop: 'static', keyboard: true })
  }

  closeModal() {
    this.modalService.hide(this.modalRef.id)
    document.body.classList.remove('modal-open')
  }

  answerModeStarted() {
    this.isExpanded = true
  }

  endAnswerMode() {
    this.isExpanded = false
    this.showQuestionnaireIcon = true
  }

  getUnreadActivities(task: TaskFe) {
    let acts = task.timelineItems.filter((item) => item.readStatus == READ_STATUS.UNREAD)
    return acts
  }

  applyFilters() {
    this.applyShowOnlyFilter()
  }

  async applyShowOnlyFilter() {
    this.tasks = await this.stateService.getTasks()
    if (this.selectedStatus) {
      this.tasks = this.tasks.filter((f) => f.status == this.selectedStatus)
    }
    this.applyCustomFilters()
  }

  clearAllFilters() {
    this.customMultipleFilters = []
    this.selectedStatus = null
    this.applyFilters()
  }

  changeCustomFilters(event: Event, value: any, isSM = false) {
    if ((event.target as HTMLInputElement).checked) {
      if (isSM) {
        this.customMultipleFilters.push({ name: value.name, value: value.affId, type: 'SM' })
      } else {
        this.customMultipleFilters.push({ name: value.name, value: value.companyId, type: 'COMPANY' })
      }
    } else {
      if (isSM) {
        this.customMultipleFilters = this.customMultipleFilters.filter((f) => f.value != value.affId)
      } else {
        this.customMultipleFilters = this.customMultipleFilters.filter((f) => f.value != value.companyId)
      }
    }
    this.applyFilters()
  }

  applyCustomFilters() {
    if (this.customMultipleFilters.length > 0) {
      this.tasks = this.tasks.filter((task) => {
        for (let f of this.customMultipleFilters) {
          if (f.type == 'SM') {
            if (task.requesterAffId == f.value) {
              return true
            }
          } else {
            if (task.requesterCompanyId == f.value) {
              return true
            }
          }
        }
        return false
      })
    }
    this.applySearchFilter()
  }

  isCustomFilterSelected(value: any) {
    return this.customMultipleFilters.find((f) => f.value == value)
  }

  applySearchFilter(keyword?: string) {
    if (keyword || keyword == '') {
      this.searchKeyword = keyword
    }
    this.filteredTasks = this.tasks.filter((task) => {
      if (task.title.match(new RegExp(this.searchKeyword, 'i'))) {
        return true
      }
    })
    this.adjustInboxData()
  }

  async filterCompanies(keyword: string = '') {
    let relatedCompanyIds = new Map()
    let tasks = await this.stateService.getTasks()
    tasks.forEach((task) => relatedCompanyIds.set(task.requesterCompanyId, task.requesterCompanyName))
    this.filteredCompanies = []
    relatedCompanyIds.forEach((name, companyId) =>
      name.match(new RegExp(keyword, 'i')) ? this.filteredCompanies.push({ name, companyId }) : null
    )
  }

  async filterSMs(keyword: string = '') {
    let relatedContactAffIds = new Map()
    let tasks = await this.stateService.getTasks()
    tasks.forEach((task) => relatedContactAffIds.set(task.requesterAffId, task.getRequesterName()))
    this.filteredSMs = []
    relatedContactAffIds.forEach((name, affId) =>
      name.match(new RegExp(keyword, 'i')) ? this.filteredSMs.push({ name, affId }) : null
    )
  }

  deselectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className)
    Array.from(allCheckboxes).forEach((el: HTMLInputElement) => (el.checked = false))
    if (className == 'companyCheckbox') {
      this.filteredCompanies.forEach((company) => {
        this.customMultipleFilters = this.customMultipleFilters.filter((f) => f.value != company.companyId)
      })
    } else if (className == 'contactCheckbox') {
      this.filteredSMs.forEach((contact) => {
        this.customMultipleFilters = this.customMultipleFilters.filter((f) => f.value != contact.affId)
      })
    }
    this.applyFilters()
  }

  selectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className)
    Array.from(allCheckboxes).forEach((el: HTMLInputElement) => (el.checked = true))
    if (className == 'companyCheckbox') {
      this.filteredCompanies.forEach((company) => {
        if (!this.customMultipleFilters.find((f) => f.value == company.companyId)) {
          this.customMultipleFilters.push({ name: company.name, value: company.companyId, type: 'COMPANY' })
        }
      })
    } else if (className == 'contactCheckbox') {
      this.filteredSMs.forEach((contact) => {
        if (!this.customMultipleFilters.find((f) => f.value == contact.affId)) {
          this.customMultipleFilters.push({ name: contact.name, value: contact.affId, type: 'SM' })
        }
      })
    }
    this.applyFilters()
  }

  getCustomFilterText() {
    let text = ''
    if (this.selectedStatus == REQUEST_TASK_STATUS.CREATED) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.sent_requests')}`
    } else if (this.selectedStatus == REQUEST_TASK_STATUS.IN_PROGRESS) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.progress_requests')}`
    } else if (this.selectedStatus == REQUEST_TASK_STATUS.APPROVED) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.completed_requests')}`
    }
    if (this.selectedStatus && this.customMultipleFilters.length > 0) {
      text += ` ${this.locale('locale_key.pages.datahub.data_entries.and')} `
    }
    text += this.customMultipleFilters.map((f) => f.name).join(', ')
    return text
  }

  openQuestionnaire() {
    if (!this.selectedTask) {
      if (this.getToDoActivitiesGroup().length > 0) {
        this.selectNewActivity(0)
      } else if (this.getDoneRequestList().length > 0) {
        this.selectDoneRequest(this.getDoneRequestList()[0])
      }
    }
    this.showQuestionnaireIcon = false
    this.cd.detectChanges()
  }
}

<!-- Auto save modal -->
<div *ngIf="shouldShowAutoSaveModal">
  <div class="d-flex justify-content-between alert-modal-header">
    <div class="d-flex">
      <h4
        *ngIf="loadingInProgress || isAutoSavingAnswersFailed"
        class="bold fs-3 modal-title mb-0 pb-0 ms-2 ps-2 mb-0 pt-2"
        id="myModalLabel12"
      >
        <i
          *ngIf="isAutoSavingAnswersFailed"
          class="warning la la-exclamation-circle fs-4"
          style="color: rgba(228, 150, 61, 1)"
        ></i>
        {{ isAutoSavingAnswersFailed ? slocale('Saving failed') : slocale('Saving your progress') }}
      </h4>

      <div class="ball-pulse loader-primary text-center ps-3 pt-3" *ngIf="loadingInProgress">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <button
        *ngIf="!isAutoSavingAnswersFailed"
        type="button"
        class="btn close"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div *ngIf="loadingInProgress">
      <h6>{{ slocale('The system is automatically saving your latest progress to prevent data loss.') }}</h6>
      <h6>{{ slocale('This message will close automatically once the save is complete.') }}</h6>
    </div>

    <div *ngIf="isAutoSavingAnswersFailed">
      <h6>The system failed to save your latest progress.</h6>
      <br />
      <h6>
        You can try saving again or leave the page without saving. Your last auto-saved progress is safe, and you can
        continue from there later.
      </h6>
    </div>
  </div>
  <div class="modal-footer" [ngClass]="{ 'no-border': !isAutoSavingAnswersFailed }" style="padding: initial">
    <ng-container *ngIf="isAutoSavingAnswersFailed">
      <button
        type="button"
        class="m-2 saving-modal-btn-font-size leave-without-saving-btn btn custom-button"
        (click)="completeAnswering()"
      >
        {{ slocale('Leave without saving') }}
      </button>
      <button
        type="button"
        class="m-2 saving-modal-btn-font-size btn btn-add text-white custom-button"
        (click)="retry()"
      >
        {{ slocale('Save again') }}
      </button>
    </ng-container>
  </div>
</div>

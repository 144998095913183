import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'

@Component({
  selector: 'app-auto-save-answers-modal',
  templateUrl: './auto-save-answers-modal.component.html',
  styleUrls: ['./auto-save-answers-modal.component.scss']
})
export class AutoSaveAnswersModalComponent extends AbstractLanguageComponent implements OnInit {
  @Input() shouldShowAutoSaveModal: boolean
  @Input() loadingInProgress: boolean
  @Input() isAutoSavingAnswersFailed: boolean
  @Output() closeModalEvent: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() completeAnsweringEvent: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() retrySavingAnswers: EventEmitter<boolean> = new EventEmitter<boolean>()

  constructor(languageService: LanguageService) {
    super(languageService)
  }

  ngOnInit(): void {}

  close() {
    this.closeModalEvent.emit(false)
  }

  completeAnswering() {
    this.completeAnsweringEvent.emit(true)
  }

  retry() {
    this.retrySavingAnswers.emit(true)
  }
}
